import firebase from "firebase/app";
import { APIErrorResponse, isUserMeResponse, UserMeResponse } from "../models/responses";
import { API_BASE_URL, getApiRequestHeaders, returnError } from "./ApiCommon";

export const API_USERS_ENDPOINT = 'users/'


export const getUserMe = async (user: firebase.User, onSuccess: (data: UserMeResponse) => void, onError: (data: APIErrorResponse) => void): Promise<void> => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'GET',
        headers: headers
    };

    fetch(API_BASE_URL + API_USERS_ENDPOINT + 'me', request)
        .then(response => response.json())
        .then(data => {
            if (isUserMeResponse(data)) {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        })
}

// Don't bother passing what you can't update
export const updateUser = async (user: firebase.User, display_name: string, onSuccess: (data: UserMeResponse) => void, onError: (data: APIErrorResponse) => void): Promise<void> => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ display_name: display_name })
    };

    fetch(API_BASE_URL + API_USERS_ENDPOINT + 'me/update', request)
        .then(response => response.json())
        .then(data => {
            if (isUserMeResponse(data)) {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        });  
}


export const deleteTruths = async (user: firebase.User, onSuccess: (data: UserMeResponse) => void, onError: (data: APIErrorResponse) => void): Promise<void> => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'POST',
        headers: headers
    };

    fetch(API_BASE_URL + API_USERS_ENDPOINT + 'me/delete_truths', request)
        .then(response => response.json())
        .then(data => {
            if (isUserMeResponse(data)) {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        });  
}
