import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OnlineGame } from "../../models/game";
import { GameServiceResponse } from "../../models/responses";

const initialState: OnlineGame = {
    id: undefined,
    hostId: undefined,
    players: [],
    createdTime: undefined,
    currentPlayerIndex: 0,
    currentPlayerId: undefined,
    previousPlayerId: undefined,
    turnOrder: [],
    turnInProgress: false,
    turnEndTime: undefined,
    gameStarted: false,
    gameOver: false,
    gameOverReason: undefined,
    allLiesSeen: false,
    previousStatement: undefined,
    round: 0,
    winners: undefined,
    // Don't assume any default settings,
    // leave that to the API
    settings: undefined,
    explainer: undefined,
    gameRevealSeen: false,
}

export const onlineGameSlice = createSlice({
    name: "game",
    initialState: initialState,
    reducers: {
        // We will call this after every request to a game endpoint.
        updateGame: (state, action: PayloadAction<GameServiceResponse>) => {
            // Overwrite state with payload content
            const gameResp: GameServiceResponse = action.payload;
            state.id = gameResp.id;
            state.hostId = gameResp.host_id;
            // Sort players by name
            gameResp.player_list.sort(
                (a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
            )

            state.players = gameResp.player_list;
            state.createdTime = gameResp.created_time;
            state.currentPlayerIndex = gameResp.current_player_index;
            state.currentPlayerId = gameResp.current_player_id;
            state.previousPlayerId = gameResp.previous_player_id;
            state.turnOrder = gameResp.turn_order;
            state.turnInProgress = gameResp.turn_in_progress;
            // Not currently supported
            // state.turnEndTime = gameResp.turn_end_time;
            state.gameStarted = gameResp.game_started;
            state.gameOver = gameResp.game_over;
            state.gameOverReason = gameResp.game_over_reason;
            state.allLiesSeen = gameResp.all_lies_seen;
            state.round = gameResp.round;
            state.winners = gameResp.winners;
            state.settings = gameResp.settings;
            state.previousStatement = gameResp.previous_statement;
            state.currentStatement = gameResp.current_statement;
            state.explainer = gameResp.explainer;
        },
        setGameRevealSeen: (state, action) => {
            state.gameRevealSeen = action.payload;
        },
        resetGame: (state) => {
            state.id = undefined;
            state.hostId = undefined;
            state.players = [];
            state.createdTime = undefined;
            state.currentPlayerIndex = 0;
            state.currentPlayerId = undefined;
            state.turnOrder = [];
            state.turnInProgress = false;
            state.turnEndTime = undefined;
            state.gameStarted = false;
            state.gameOver = false;
            state.gameOverReason = undefined;
            state.allLiesSeen = false;
            state.round = 0;
            state.settings = undefined;
            state.previousStatement = undefined;
            state.currentStatement = undefined;
            state.explainer = undefined;
            state.gameRevealSeen = false;
            state.previousPlayerId = undefined;
        }
    }
})

export const {
    updateGame,
    setGameRevealSeen,
    resetGame
} = onlineGameSlice.actions;
