import { GameSettings, OnlinePlayer, OnlineStatement } from "./game";

export type PurchaseVerificationResponse = {
    message: string,
    statusCode: number,
    purchaseSuccess: boolean,
    productId?: string
}

export type UserMeResponse = {
    id: string,
    display_name: string,
    games_won: number,
    expansions_owned: string[],
    truths: string[],
    current_game_id: string | null
}


export type GameServiceResponse = {
    id: string,
    host_id: string,
    // Look at formatting this as list on Python side before returning to FE
    //players: object, //Map<string, OnlinePlayer>, //{ [playerId: string]: OnlinePlayer }[],
    player_list: OnlinePlayer[],
    created_time: string,
    current_player_index: number,
    current_player_id: string,
    previous_player_id?: string,
    turn_order: string[],
    current_statement?: OnlineStatement,
    previous_statement?: OnlineStatement,
    turn_in_progress: boolean,
    turn_end_time?: string,
    game_started: boolean,
    game_over: boolean,
    game_over_reason?: string,
    all_lies_seen: boolean,
    round: number,
    winners?: OnlinePlayer[],
    settings: GameSettings,
    explainer?: string,
}

export type UpdateUserResponse = UserMeResponse;

export type APIErrorResponse = {
    detail: string
}

export function isError(test: any): test is APIErrorResponse {
    return test.detail !== undefined;
}

export function isUserMeResponse(test: any): test is UserMeResponse {
    return test.display_name !== undefined;
}

export function isGameServiceResponse(test: any): test is GameServiceResponse {
    return test.host_id !== undefined;
}