import { auth } from "../../services/firebase";
import { Button, makeStyles } from "@material-ui/core";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase';
import { useState, useEffect } from "react";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from "react-redux";
import { resetProducts, setFirebaseUser, setApiUser } from '../../store/state/user';
import { toggleProfileModalOpen, setToast } from "../../store/state/ui";
import { AppState } from '../../store/store';
import { ProductIdToNameMap } from "../../services/DigitalGoodsService";
// import { getUserToken } from "../../helpers/UserHelpers";
import { deleteTruths, getUserMe, updateUser } from "../../services/APIUserService";
import { APIErrorResponse, UserMeResponse } from "../../models/responses";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import StarsIcon from '@material-ui/icons/Stars';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  profileButton: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    right: 0,
  },
  container: {
    display: "flex",
    minHeight: 250,
    flexDirection: "column",
    alignItems: "center",
    padding: "0 18px",
    textAlign: "center",
    "& h1": {
      textShadow: 'none',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  expansionContainer: {
  }
});

export interface DialogTitleProps  {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography style={{ display: 'flex', alignItems: 'center', width: "100%", padding: 0 }} {...other}>
      {onClose ? (
        <IconButton aria-label="close" style={{ padding: 0, paddingTop: 8, marginLeft: "auto" }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false
    },
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      requireDisplayName: true
    }
  ],
  callbacks: {
    // Return false to avoid redirects after sign-in.
    signInSuccessWithAuthResult: (authResult: unknown, redirectUrl: string) => {

      return false;
    },
  }
};
// const dgService = getService();
  
export const AccountManagement = () => {
  const classes = useStyles();
  const firebaseUser = useSelector((x: AppState) => x.userReducer.firebaseUser);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const open = useSelector((x: AppState) => x.uiReducer.profileModalOpen);
  const [updating, setUpdating] = useState(false);

  const storeOwnedProductIds = useSelector((x: AppState) => x.userReducer.apiUser?.expansions_owned) || [];
  // const [dgOwnedProductIds, setDgOwnedProductIds] = useState<string[]>([]);
  // const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const apiUserDisplayName = useSelector((x: AppState) => x.userReducer.apiUser?.display_name) || null;
  const unusedTruths = useSelector((x: AppState) => x.userReducer.apiUser?.truths) || [];
  const [displayName, setDisplayName] = useState<string | null>(apiUserDisplayName);
  const [textFieldDisplayName, setTextFieldDisplayName] = useState<string | null>(apiUserDisplayName);


  const dispatch = useDispatch();

  useEffect(() => {
    if (apiUserDisplayName !== displayName) {
      setDisplayName(apiUserDisplayName);
      setTextFieldDisplayName(apiUserDisplayName);
    }
  }, [apiUserDisplayName, displayName])

  useEffect(() => {
    if (open) {
      const user = firebase.auth().currentUser;
      !!user && getAPIUserDetails(user);
    }
  }, [open])


  const getAPIUserDetails = async (firebaseUser: firebase.User) => {
    // Get current user data from API
    await getUserMe(
      firebaseUser,
      (data: UserMeResponse) => {
        setTextFieldDisplayName(data.display_name);
        setDisplayName(data.display_name);
        // Set API user in state
        dispatch({ type: setApiUser, payload: { apiUser: data } });
      },
      (data: APIErrorResponse) => {
        dispatch({ type: setToast, payload: { message: data.detail, severity: "error" } });
      }
    )
  }

  const onClickUpdate = async () => {
    const user = firebase.auth().currentUser;

    if (textFieldDisplayName && user) {
      await updateUser(
        user,
        textFieldDisplayName,
        (data: UserMeResponse) => {
          dispatch({type: setApiUser, payload: { apiUser: data }});
          setDisplayName(data.display_name);
          setTextFieldDisplayName(data.display_name);

          getAPIUserDetails(user);
          dispatch({ type: setToast, payload: { message: "Display name updated", severity: "success" } });
        },
        (data: APIErrorResponse) => {
          getAPIUserDetails(user);
          dispatch({ type: setToast, payload: { message: data.detail, severity: "error" } });
        }
      );
    }
  }

  const onClickDeleteTruths = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      await deleteTruths(
        user,
        (data: UserMeResponse) => {
          dispatch({type: setApiUser, payload: { apiUser: data }});
          setDisplayName(data.display_name);
          setTextFieldDisplayName(data.display_name);
  
          getAPIUserDetails(user);
          dispatch({ type: setToast, payload: { message: "Truths deleted", severity: "success" } });
        },
        (data: APIErrorResponse) => {
          getAPIUserDetails(user);
          dispatch({ type: setToast, payload: { message: data.detail, severity: "error" } });
        }
      );
    }
  }
    
  const content = () => {
    if (!isSignedIn) {
      return (
        <div className={classes.container}>
          <DialogTitle id="customized-dialog-title" onClose={() => dispatch({ type: toggleProfileModalOpen })}>
          </DialogTitle>
          <p>Sign-in or register</p>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
          <Button
            color="primary"
            variant="contained"
            onClick={() => dispatch({ type: toggleProfileModalOpen })}
            style={{ marginTop: 'auto', marginBottom: 16 }}
          >
            Close
          </Button>
        </div>
      );
    }
    
    return (
      <div className={classes.container}>
        <DialogTitle id="customized-dialog-title" onClose={() => dispatch({ type: toggleProfileModalOpen })}>
        </DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AccountCircleIcon color="primary" style={{ marginRight: 8 }} />
          <p style={{ fontSize: 24 }}>Welcome {displayName}</p>
        </div>

        <p style={{ fontSize: 18, fontWeight: 600 }}>Display name</p>
        <TextField
          variant="outlined"
          value={textFieldDisplayName}
          onChange={(e) => setTextFieldDisplayName(e.target.value)}
          inputProps={{ maxLength: 20 }}
          style={{ width: 250 }}
        />

        <p style={{ fontSize: 18, fontWeight: 600 }}>Email</p>
        <TextField
          variant="outlined"
          value={firebaseUser?.email}
          style={{ width: 250 }}
          disabled
        />

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!updating && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {setUpdating(true); onClickUpdate(); setUpdating(false);}}
              disabled={displayName === textFieldDisplayName || updating}
              size="small"
              style={{ marginTop: 8 }}
            >Update</Button>
          )}
          {updating && (
            <CircularProgress style={{ marginTop: 8 }} />
          )}
        </div>
        <p style={{ fontSize: 18, fontWeight: 600, margin: 16 }}>Premium features</p>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p style={{ fontSize: 16, flex: 1, margin: 16, marginTop: 24 }}>You have <b>{unusedTruths.length.toString()} unused truths</b> saved to your account {unusedTruths.length > 0 ? 'ready to copy into your next game.' : '.  Unused truths save automatically when you leave or finish a game.'}</p>
          {
            unusedTruths.length > 0 && (
              <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {setUpdating(true); onClickDeleteTruths(); setUpdating(false);}}
                  disabled={unusedTruths.length == 0}
                  size="small"
                  style={{ marginTop: 8 }}
                >Clear all</Button>
            )
          }
            <p style={{ fontSize: 16, flex: 1, margin: 16, marginTop: 24 }}>All game settings are unlocked</p>
        </div>

        <p style={{ fontSize: 18, fontWeight: 600, margin: 16 }}>Owned Expansions</p>
        {!!storeOwnedProductIds.length ? (
            <div style={{ marginBottom: 16 }}>
              <Alert severity="info" style={{ marginBottom: 16 }}>
                <b>You've got free premium, yay!</b>
                <p style={{ fontSize: 12, margin: 0 }}>If we get any spikes in usage, we may have to consider introducing paid premium.</p>
              </Alert>
              <div className={classes.expansionContainer}>
                {storeOwnedProductIds.map((storeOwnedProductId: string) => (
                  // <p style={{ fontSize: 16, margin: 0 }}>{ProductIdToNameMap[storeOwnedProductId]}</p>
                  <Chip
                    icon={<StarsIcon />}
                    label={ProductIdToNameMap[storeOwnedProductId]}
                    color="primary"
                    style={{ marginRight: 4, marginBottom: 4 }}
                    // onDelete={handleDelete}
                    // deleteIcon={<DoneIcon />}
                  />
                ))}
              </div>
            </div>
          )
          :
          (
            <p style={{ fontSize: 12 }}>You don't have any expansions.</p>
          )
        }
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            firebase.auth().signOut();
          }}
          style={{ marginTop: "auto", marginBottom: 16 }}
        >
          Sign out
        </Button>
      </div>
    )
  }


  useEffect(() => {
    // Listen to the Firebase Auth state and set the local state.
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      // const setFirebaseToken = async () => {
      //   const token = await getUserToken(user);
      //   setAuthToken(token);
      // }
      setIsSignedIn(!!user);

      if (user !== null) {
        // Set firebase user in state
        dispatch({ type: setFirebaseUser, payload: { firebaseUser: user?.toJSON() } });

        // Set a token to display
        // setFirebaseToken();
      }
      else {
        // Logout & reset state
        dispatch({ type: setFirebaseUser, payload: { firebaseUser: null } });
        dispatch({ type: setApiUser, payload: { apiUser: null } });
        dispatch({ type: resetProducts });

        dispatch({ type: toggleProfileModalOpen });
      }
      
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [dispatch]);

  useEffect(() => {
    if (!apiUserDisplayName && firebaseUser) {
      // Fetch API user information if it's not already populated
      getAPIUserDetails(firebaseUser);
      dispatch({ type: toggleProfileModalOpen });
    }
  }, [firebaseUser?.uid])


  return (
    <>
      {isSignedIn ?
        <IconButton aria-label="Account" className={classes.profileButton} onClick={() => dispatch({ type: toggleProfileModalOpen })}>
          <AccountCircleIcon color="primary" fontSize="large" />
        </IconButton>
        :
        <Button
          color="primary"
          variant="contained"
          className={classes.profileButton}
          onClick={() => dispatch({ type: toggleProfileModalOpen })}
          style={{ margin: 8 }}
        >
          Sign in
        </Button>
      }
        
      <Dialog
        open={open}
        onClose={() => dispatch({ type: toggleProfileModalOpen })}
        aria-labelledby="account-management"
        aria-describedby="manage-username-and-expansions"
      >
        {content()}
      </Dialog>
    </>
  )
}
