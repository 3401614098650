import { Button, makeStyles, Typography } from "@material-ui/core";
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import ProductCard from '../molecules/ProductCard';
import { toggleStoreModalOpen, toggleProfileModalOpen } from '../../store/state/ui';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { getService } from "../../services/DigitalGoodsService";
import { makePurchase } from "../../services/PurchaseService";
import firebase from "firebase/app";
import { useState, useEffect } from "react";
import { addProductToUser } from "../../store/state/user";
import CircularProgress from '@material-ui/core/CircularProgress';
import { PurchaseVerificationResponse } from "../../models/responses";

const useStyles = makeStyles({
  storeButton: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    right: 90
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 24,
    textAlign: "center",
    "& h1": {
      textShadow: 'none',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
});
  

  export const StoreModal = (props: { showButton?: boolean }) => {
    const dispatch = useDispatch();

    // Disable store for now, we'll be re-implementing in a native app container
    const { showButton=false } = props
  
    const classes = useStyles();
    const open = useSelector((x: AppState) => x.uiReducer.storeModalOpen);
    const user = useSelector((x: AppState) => x.userReducer.firebaseUser);
    const ownedProducts = useSelector((x: AppState) => x.userReducer.ownedProductIds);
    const DGService = getService();
    const [processing, setProcessing] = useState(false);
    const [storeMessage, setStoreMessage] = useState('');
    const [verificationResp, setVerificationResp] = useState<PurchaseVerificationResponse | null>(null);

    const triggerPaymentFlow = async (DGService: unknown, sku: string, user: firebase.User) => {
      setProcessing(true);
      setStoreMessage('');
      // This should be calling the makePurchase function
      let verificationResp = await makePurchase(DGService, sku, user);
      verificationResp.productId = sku;
      setVerificationResp(verificationResp);
    }

    useEffect(() => {
      if (verificationResp != null) {
        // Display store message
        if (verificationResp?.message) setStoreMessage(verificationResp.message);
        if (verificationResp?.purchaseSuccess) {
          dispatch({ type: addProductToUser, payload: { sku: verificationResp?.productId ?? null } });
        }
        setProcessing(false);
      }
    }, [verificationResp, dispatch]);

    const content = () => {
        if (processing) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',padding: 64 }}>
              <div style={{padding:8}}>Processing...</div>
              <CircularProgress />
            </div>
          );
        }
        if (!user) {
          return (<div className={classes.container}>
            <Typography variant="h4">Store</Typography>
            Please sign in to use the store.
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatch({ type: toggleProfileModalOpen })}
              style={{ marginTop: 16 }}
            >
              Sign in
            </Button>
          </div>)
        }

        return(
          <div className={classes.container}>
            <Typography variant="h4">Store</Typography>
            {
              DGService ?
                <>
                    {/* TODO: Iteratively populate */}
                   <ProductCard
                      productId='offline_mode_full_features'
                      productTitle="Fact or Fib Offline Premium"
                      productPrice="£6.99 one time payment"
                      productDescription="Unlocks all settings and removes ads for offline games."
                      buttonText="Purchase"
                      isOwned={ownedProducts.includes('offline_mode_full_features')}
                      onClick={() => triggerPaymentFlow(DGService, 'offline_mode_full_features', user)}
                    />
                    {
                      storeMessage ?
                        <div style={{padding:8}}>{storeMessage}</div>
                      :
                        null
                    }
                </>
              :
                <div style={{padding:8}}>We can only accept payments on the Android app at the moment, but we're working to change that.</div>
            }
          </div>
        )
    }

    return (
      <>
        {showButton && (
          <IconButton
            aria-label="Store"
            className={classes.storeButton}
            onClick={() => dispatch({ type: toggleStoreModalOpen })}
            style={{ right: user ? 60 : 90 }}
          >
            <LocalGroceryStoreIcon color="primary" fontSize="large" />
          </IconButton>
        )}
        <Dialog
          open={open}
          onClose={() => {
            dispatch({ type: toggleStoreModalOpen });
            setStoreMessage('');
          }}
          aria-labelledby="store"
          aria-describedby="in-app-store"
        >
          {content()}
        </Dialog>
      </>
  )

}
