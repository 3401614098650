import moment from "moment";

export interface Statement {
  id: number;
  text: string;
  used: boolean;
  factOrFib: FactOrFib;
}

export interface OnlineStatement {
  id: string;
  text: string;
  used?: boolean;
  factual?: boolean;
}

export interface Player {
  id: number;
  name: string;
  points: number;
  truths: Statement[];
}

export function isLocalPlayer(test: any): test is Player {
  return test.truths !== undefined && test.truths_count === undefined;
}

export function isLocalPlayerList(test: any): test is Player[] {
  return test.length === 0 || test[0].truths !== undefined;
}


export enum FactOrFib {
  Fact = "Fact",
  Fib = "Fib",
}

export interface Game {
  id: number;
  players: Player[];
  currentPlayerIndex: number;
  turnInProgress: boolean;
  currentStatement: Statement | null;
  previousStatement: Statement | null;
  guess: FactOrFib | null;
  previousGuess: FactOrFib | null;
  lies: Statement[];
  settings: GameSettings;
  gameOver: boolean;
  gameOverReason?: string | null;
  round: number;
  allLiesSeen: boolean;
  turnEndTime?: moment.Moment;
}

export interface GameSettings {
  // Percentage chance of player being presented with a truth
  truthChance: number;
  // Optional game settings
  numRounds?: number;
  winningNumPoints?: number;
  maxMinutesPerTurn?: number;
}

export interface OnlineGameSettings {
  truth_chance: number;
  num_rounds?: number;
  winning_num_points?: number;
  max_minutes_per_turn?: number;
}

export interface OnlinePlayer {
  user_id: string;
  name: string;
  points: number;
  successful_deceptions: number;
  successful_deductions: number;
  previous_guess?: boolean;
  guess?: boolean;
  ready: boolean;
  is_host: boolean;
  truths: OnlineStatement[];
  truths_count: number;
}

export function isOnlinePlayer(test: any): test is OnlinePlayer {
  return test && test.user_id !== undefined;
}

export type OnlinePlayerMap = Map<string, OnlinePlayer>;


// TODO: Consider aligning game state interfaces or setting up
// inheritance of some kind for common interface overlap (DRY).
export interface OnlineGame {
  id?: string;
  hostId?: string;
  players: OnlinePlayer[];
  createdTime?: string;
  currentPlayerIndex?: number;
  currentPlayerId?: string;
  previousPlayerId?: string;
  turnOrder: string[];
  currentStatement?: OnlineStatement;
  previousStatement?: OnlineStatement;
  turnInProgress: boolean;
  turnEndTime?: string;
  gameStarted: boolean;
  gameOver: boolean;
  gameOverReason?: string;
  allLiesSeen: boolean;
  round: number;
  winners?: OnlinePlayer[];
  settings?: GameSettings;
  explainer?: string;
  gameRevealSeen?: boolean;
}
