/**
 * TapToReveal
 * 
 * Local play: Tap it to toggle and reveal the content
 * Online game: Tap is disabled, can only be revealed with the value of 'revealed' being true
 */
import React, { ReactNode } from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    maxWidth: 300,
    justifyContent: "center",
    padding: "16px 8px",
    borderRadius: 10,
    border: "3px solid #e6b800",
    backgroundColor: "#a4c3d2",
    boxShadow: "5px 8px",
  },
});

interface TapToRevealProps {
  preRevealText: string;
  revealContent: ReactNode;
  revealed: boolean;
  setRevealed?: (value: boolean) => void;
  onReveal?: () => void;
  isOnlineGame?: boolean;
}

export const TapToReveal = (props: TapToRevealProps) => {
  const { preRevealText, revealContent, onReveal, revealed, setRevealed, isOnlineGame = false } = props;
  const classes = useStyles();

  const onContainerClick = () => {
    if (!revealed && !isOnlineGame) {
      setRevealed && setRevealed(true);
      onReveal && onReveal();
    }
  }

  return (
    <div className={classes.container} onClick={onContainerClick} style={{ cursor: revealed || isOnlineGame ? undefined : "pointer" }}>
      {revealed ?
        revealContent
        :
        <Typography>{preRevealText}</Typography>
      }
    </div>
  );
};
