import { listHasUnusedStatement } from "./GameHelpers";
import { Game, Statement } from "../models/game";
import { generateLieStatements } from "../store/data";

export const gameIsOver = (gameState: Game): string | null => {
  // Has a player run out of truths
  for (let i = 0; i < gameState.players.length; i++) {
    if (!listHasUnusedStatement(gameState.players[i].truths)) {
      return "A player has run out of truths";
    }
  }
  // Did we reach max rounds?
  if (
    gameState.settings.numRounds &&
    gameState.round >= gameState.settings.numRounds - 1
  )
    return "The game reached the set number of rounds";

  // Did a player hit winning points?
  if (gameState.settings.winningNumPoints) {
    for (let i = 0; i < gameState.players.length; i++) {
      if (gameState.players[i].points >= gameState.settings.winningNumPoints)
        return "A player reached the winning number of points";
    }
  }

  // No game over criteria met
  return null;
};

export const getStatement = (
  state: Game,
  resetLiesIfSeen: boolean = false
): Statement | null => {
  // Reset lies if all lies have been seen
  if (resetLiesIfSeen && !listHasUnusedStatement(state.lies)) {
    state.lies = generateLieStatements();
    state.allLiesSeen = false;
  }

  // If we didn't reset and there are no unseen lies, return null
  if (state.allLiesSeen) return null;

  // Select the next statement based on truth chance
  const randNum = Math.floor(Math.random() * 100 + 1);
  let statement: Statement | null = null;
  if (randNum <= state.settings.truthChance) {
    // Get an unseen truth
    statement =
      state.players[state.currentPlayerIndex].truths.find((t) => !t.used) ??
      null;
    // Mark it as seen
    const targetTruthIndex = state.players[
      state.currentPlayerIndex
    ].truths.findIndex((t) => t.text === statement?.text);
    if (targetTruthIndex)
      state.players[state.currentPlayerIndex].truths[
        targetTruthIndex
      ].used = true;
  } else {
    // Get an unseen lie
    statement = state.lies.find((l) => !l.used) ?? null;
    // Mark it as seen
    const targetLieIndex = state.lies.findIndex(
      (l) => l.text === statement?.text
    );
    if (targetLieIndex !== null) state.lies[targetLieIndex].used = true;
  }

  return statement;
};
