import React, { ReactNode, useState, useEffect } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from "@material-ui/core/Paper";
import { makeStyles, Button, CircularProgress, Typography } from "@material-ui/core";
import { isLocalPlayer, isLocalPlayerList, isOnlinePlayer, OnlinePlayer, Player } from "../../models/game";
import { useDispatch, useSelector } from "react-redux";
import { updatePlayer, resetGame } from "../../store/state/game";
import { updateGame, resetGame as resetOnlineGame } from "../../store/state/online-game";
import Modal from '@material-ui/core/Modal';
import { EditPlayer } from '../../components/organisms/EditPlayer';
import { Link, useHistory } from "react-router-dom";
import { AppState } from "../../store/store";
import GroupIcon from '@material-ui/icons/Group';
import { AdBanner } from "../../components/atoms/AdBanner";
import { AccountManagement } from "../../components/organisms/AccountManagement";
import { StoreModal } from "../../components/organisms/StoreModal";
import { updateTruths, getGame, toggleReady, leaveGame, startGame as startOnlineGame } from "../../services/ApiGameService";
import { APIErrorResponse, GameServiceResponse } from "../../models/responses";
import { setToast } from "../../store/state/ui";
import firebase from 'firebase';
import { EditOnlinePlayer } from "../../components/organisms/EditOnlinePlayer";
import TextField from "@material-ui/core/TextField";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { getOnlinePlayerById } from "../../helpers/GameHelpers";
import { useInterval } from "../../helpers/useInterval";
import { API_POLLING_ENABLED, API_UPDATE_POLL_TIME } from "../../services/ApiCommon";

import { db } from "../../services/firebase";


const useStyles = makeStyles({
  table: {
    width: "100%",
  },
});

interface PlayerManagementProps {
  isOnlineGame?: boolean;
  nextPagePath?: string;
  nextPageButtonText?: string;
}

interface Row {
  name: string,
  truths: number,
  edit: ReactNode,
  ready: boolean,
}

function createData(_name: string, truths: number, ready: boolean, edit: ReactNode, isHost: boolean = false, isMe: boolean = false) {
  let name = _name;
  if (isMe) name = name + ' (me)';
  if (isHost) name = name + ' [Host]';

  return { name, truths, ready, edit };
}

export const PlayerManagement = (props: PlayerManagementProps) => {
  const classes = useStyles();
  // Make sure we have a useEffect on the players upstream to respond to API actions
  const { isOnlineGame = false, nextPagePath = '/game-settings', nextPageButtonText = 'Continue' } = props;

  const onlinePlayers = useSelector((x: AppState) => x.onlineGameReducer.players) || [];
  const localPlayers = useSelector((x: AppState) => x.gameReducer.players) || [];
  const rows: Row[] = [];

  let players: Player[] | OnlinePlayer[] = isOnlineGame ?
          onlinePlayers
        :
          localPlayers

  const numPlayers = players instanceof Map ? players.size : players.length;
  const onlineGame = useSelector((x: AppState) => x.onlineGameReducer);
  const currentUser = useSelector((x: AppState) => x.userReducer.apiUser);

  // Current user stuff
  const firebaseUser = firebase.auth().currentUser;

  const myOnlinePlayer: OnlinePlayer | null = currentUser ? getOnlinePlayerById(onlinePlayers, currentUser?.id) : null;

  const dispatch = useDispatch();
  const history = useHistory();

  const [ playerToEdit, setPlayerToEdit ] = useState<Player | OnlinePlayer | null>(null);
  const [ showModal, setShowModal ] = useState<boolean>(false);
  const [ validationText, setValidationText ] = useState("");
  const [loading, setLoading] = useState(false);

  const handleReset = () => {
    if (!isOnlineGame) dispatch({ type: resetGame });
  }


  const handleExit = async () => {
    // Call leave game endpoint if online
    history.push('/')

    if (firebaseUser) {
      await leaveGame(
        onlineGame,
        firebaseUser,
        () => {
          dispatch({ type: resetOnlineGame });
        },
        (error: APIErrorResponse) => {
          dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        }
      )
    }
  }

  const editButton = (player?: Player | OnlinePlayer) => (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        if (player) setPlayerToEdit(player);
        setShowModal(true);
      }}
    >
      Edit
    </Button>
  )

  if (!isOnlineGame) {
    players.forEach((player: Player | OnlinePlayer) => {
      if (isLocalPlayer(player)) {
        rows.push(createData(player.name, player.truths.length, true, editButton(player)));
      }
    });
  } else {
    onlinePlayers.forEach((player: OnlinePlayer) => {
      rows.push(createData(
        player.name,
        player.truths_count,
        player.ready,
        myOnlinePlayer?.user_id === player.user_id ? editButton(player) : <></>,
        myOnlinePlayer?.user_id === player.user_id && myOnlinePlayer?.is_host,
        myOnlinePlayer?.user_id === player.user_id
      ));
    })
  }

  const onPlayerReady = async () => {
    if (firebaseUser) {
      await toggleReady(
        onlineGame,
        firebaseUser,
        (data: GameServiceResponse) => {
          dispatch({ type: updateGame, payload: data });
        },
        (error: APIErrorResponse) => {
          dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        }
      );
    }
  }

  const onContinue = async () => {
    setLoading(true);
    setValidationText("");

    // Only run validation for local games, BE does it for online
    if (!isOnlineGame) {
      
      // Check minimum of two players
      if (numPlayers < 2) {
        setValidationText("You'll need at least two players to continue");
        return;
      }
  
      // Check players have equal number of truths
      let numTruth: number | null = null;
  
      players.forEach((p: Player | OnlinePlayer) => {
        if (isLocalPlayer(p)) {
          if (!numTruth) numTruth = p.truths.length;
          if (p.truths.length !== numTruth) {
            setValidationText("Your players should have the same number of truths each");
            return;
          }
        }
      })

      // Go to next page
      history.push(nextPagePath);
    }

    if (isOnlineGame) {
      if (firebaseUser && onlineGame.id) {
        await startOnlineGame(
          onlineGame.id,
          firebaseUser,
          (data: GameServiceResponse) => {
            dispatch({ type: updateGame, payload: data });
            // Go to next page
            history.push(nextPagePath);
            setLoading(false);
          },
          (error: APIErrorResponse) => {
            setLoading(false);
            dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
          }
        )
      }
    }
  }

  let initState = true;

  useEffect(() => {
    // Handle game screen routing for non-host players
    if (onlineGame.gameStarted && !myOnlinePlayer?.is_host) {
      history.push('online-game-round');
    }

    // If we have a game, subscribe to player action updates
    let unsubscribe: undefined | (() => void) = undefined;
    if (onlineGame.id) {
      unsubscribe = db.collection("game_logs").doc(onlineGame.id)
        .onSnapshot((doc) => {
          // Ignore initial state retrieval
          if (initState) {
            initState = false;
          } else {  
              updateGameState();
          }
        });
    }
      
    return () => {
      unsubscribe && unsubscribe();
    }

  }, [onlineGame])

  useEffect(() => {
    if (isOnlineGame && !currentUser) {
      history.push('/');
    }
  }, [isOnlineGame, currentUser, history])

  const savePlayerChanges = async (player: Player | OnlinePlayer) => {
    if (isOnlinePlayer(player) && firebaseUser && player.truths) {
      // Update the online game state
      const truthsList = player.truths.map(t => t.text); 
      await updateTruths(
        truthsList,
        onlineGame,
        firebaseUser,
        (data: GameServiceResponse) => {
          dispatch({ type: updateGame, payload: data });
        },
        (error: APIErrorResponse) => {
          dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        }
      )

    } else {
      // Dispatch update player
      dispatch({ type: updatePlayer, payload: player });
    }
    // Close modal
    setShowModal(false);
  }

  const updateGameState = async () => {
    onlineGame.id && firebaseUser && (
      await getGame(
        onlineGame.id,
        firebaseUser,
        (data: GameServiceResponse) => {
          dispatch({ type: updateGame, payload: data });
          // dispatch({ type: setLastUserAction, payload: lastUpdate });
        },
        (error: APIErrorResponse) => {
          dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        }
      )
    )
  }

  useInterval(() => API_POLLING_ENABLED && updateGameState(), API_UPDATE_POLL_TIME);

  const renderRows = (rows: Row[]) => {
    return rows.map((row) => (
      <TableRow key={row.name}>
        <TableCell>
          {row.name}
        </TableCell>
        <TableCell align="right">
          {row.truths}
        </TableCell>
        {
          isOnlineGame && (
            row.ready ?
              <TableCell align="right">
                <ThumbUpIcon style={{ color: '#7FFF00' }} />
              </TableCell>
              :
              <TableCell align="right">
                <ThumbDownIcon style={{ color: '#fe7b7c' }} />
              </TableCell>
          )
        }
        <TableCell align="right">{row.edit}</TableCell>
      </TableRow>
    ));
  }

  return (
    <div className="settingsContainer">
      <div className="settingsTitleContainer">
        <GroupIcon style={{ marginRight: 8, fontSize: 40, color: "#3f51b5" }}/>
        <h1 className="settings-heading">
          Player Setup
        </h1>
      </div>
      {numPlayers === 0 ? (
        <Typography style={{marginBottom: 16, textAlign: 'center'}}>You haven't added any players yet.</Typography>
      ) : (
        <TableContainer component={Paper} style={{ maxWidth: 700 }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Players</TableCell>
                <TableCell align="right">Truths</TableCell>
                {
                  isOnlineGame && (
                    <TableCell align="right">Ready?</TableCell>
                  )
                }
                <TableCell align="right">
                  Edit truths
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderRows(rows)}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {
        isOnlineGame ? (
          <>
            <p style={{ fontSize: 16, marginBottom: 0, marginTop: 32 }}>Your friends can join with this ID</p>
            <TextField
              variant="filled"
              value={onlineGame.id}
              style={{ width: 250, marginTop: 16, marginBottom: 16, backgroundColor: "rgba(255,255,255, 1)" }}
              label="Your game ID"
            />
            {
              myOnlinePlayer?.truths.length === 0 && (
                <div className="helper-container">
                  <p className="helper-heading">Click the edit button next to your display name to add some truths.</p>
                  <p className="helper-heading">Lies will be generated during the game.</p>
                </div>
              )
            }
            <div className="buttonRow" style={{ marginBottom: 32 }}>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // updateGameState();
                }}
                style={{ marginRight: 8 }}
                endIcon={<SyncIcon />}
              >
                Refresh
              </Button> */}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onPlayerReady();
                }}
                // style={{ marginBottom: 16, marginTop: 16 }}
                style={{ marginRight: 8 }}
                endIcon={!myOnlinePlayer || !myOnlinePlayer.ready ? <ThumbUpIcon /> : <ThumbDownIcon />}
                disabled={!myOnlinePlayer?.truths || myOnlinePlayer?.truths.length === 0}
              >
                {!myOnlinePlayer || !myOnlinePlayer.ready ? 'Ready' : 'Not ready'}
              </Button>
            </div>

          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setPlayerToEdit(null);
              setShowModal(true);
            }}
            style={{ marginTop: 16, marginBottom: 16 }}
          >
            Add new player
          </Button>
        )
      }
      {validationText && <Typography className={"validation-text"}>{validationText}</Typography>}
      <div className="buttonRow" style={{ marginBottom: 16, paddingBottom: 80 }}>
        
        {
          myOnlinePlayer?.is_host && (
            !loading ?
              <Button
                variant="contained"
                onClick={() => onContinue()}
                color="primary"
                style={{ marginRight: 32 }}
                disabled={
                  numPlayers === 0 || (
                    isOnlineGame &&
                    !onlinePlayers.every((p: OnlinePlayer) => p.ready
                  )) || (
                    isOnlineGame && onlinePlayers.length < 2
                  )
                }
              >
                {nextPageButtonText}
              </Button>
            :
              <CircularProgress style={{ marginRight: 32 }} />
          )
        }
        {
          !isOnlineGame && (
            <Button
              variant="contained"
              onClick={() => onContinue()}
              color="primary"
              style={{ marginRight: 32 }}
              disabled={
                numPlayers === 0 || (
                  isOnlineGame &&
                  !onlinePlayers.every((p: OnlinePlayer) => p.ready
                )) || (
                  isOnlineGame && onlinePlayers.length < 2
                )
              }
            >
              {nextPageButtonText}
            </Button>
          )
        }
        
        {/* Reset for local game only */}
        {!isOnlineGame && (
          <Button
            variant="contained"
            onClick={() => handleReset()}
            color="secondary"
            disabled={numPlayers === 0}
            style={{ marginRight: 8 }}
          >
            Reset
          </Button>
        )}
        {
          !loading && (
            <Button
              component={ Link }
              to="/"
              variant="contained"
              onClick={() => handleExit()}
              color="secondary"
            >
              {isOnlineGame ? 'Leave' : 'Quit'}
            </Button>
          )
        }
      </div>
      <AdBanner currentPath="player-management" />
      <AccountManagement />
      <StoreModal />
      <Modal
        open={showModal}
      >
        <>
          {isOnlinePlayer(playerToEdit) ? (
            <EditOnlinePlayer
              onSave={savePlayerChanges}
              player={playerToEdit ? playerToEdit : undefined}
              onCancel={() => {
                setShowModal(false);
                setPlayerToEdit(null);
              }}
            />
          ) : (
            <EditPlayer
              onSave={savePlayerChanges}
              player={playerToEdit ? playerToEdit : undefined}
              existingPlayers={isLocalPlayerList(players) ? players : []}
              onCancel={() => {
                setShowModal(false);
                setPlayerToEdit(null);
              }}
            />
          )}
        </>
      </Modal>
    </div>
  )
}
