import firebase from "firebase";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBG4JZ83hSV5tw1UoqSjRLI0W9nd3Lm_ys",
  authDomain: "fact-or-fib.firebaseapp.com",
  projectId: "fact-or-fib",
  storageBucket: "fact-or-fib.appspot.com",
  messagingSenderId: "42625398646",
  appId: "1:42625398646:web:825d9156ec68915b1c6c57",
  measurementId: "G-6GW12ZLS92"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
export const db = app.firestore();