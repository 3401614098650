import { Typography, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { FactOrFib } from "../../models/game";
import {
  resetGame,
  resetGamePreservePlayers,
  endGame,
  resetLies,
} from "../../store/state/game";
import { ScoreBoard } from "../../components/organisms/ScoreBoard";
import { getWinners } from "../../helpers/GameHelpers";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const AnswerReveal = () => {
  const dispatch = useDispatch();
  const {
    players,
    previousStatement,
    currentPlayerIndex,
    previousGuess,
    gameOver,
    gameOverReason,
    allLiesSeen,
  } = useSelector((x: AppState) => x.gameReducer);
  const history = useHistory();
  const [showAnswer, setShowAnswer] = useState(false);
  const [resetLieModalOpen, setResetLieModalOpen] = useState(false);

  const previousPlayerIndex =
    currentPlayerIndex === 0 ? players.length - 1 : currentPlayerIndex - 1;

  const explainer = () => {
    if (!previousGuess)
      return (
        <Typography>
          {players[previousPlayerIndex].name} gets one point.
        </Typography>
      );

    return previousGuess === previousStatement?.factOrFib ? (
      <Typography>
        {players[previousPlayerIndex].name} did not successfully deceive players
        and gets no points.
      </Typography>
    ) : (
      <Typography>
        {players[previousPlayerIndex].name} successfully deceived players and
        gets one point!
      </Typography>
    );
  };

  const getBackgroundColor = () => {
    if (!showAnswer) return "#4e54c8";
    if (previousStatement?.factOrFib)
      return getStatementColour(previousStatement.factOrFib);
  };

  const getStatementColour = (statement: FactOrFib) => {
    if (statement === FactOrFib.Fact) {
      return "#77dd77";
    } else {
      return "#ff6961";
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAnswer(true);
    }, 2000);
  });

  const renderButtons = () => {
    if (showAnswer && !resetLieModalOpen) {
      return !gameOver ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            !allLiesSeen
              ? history.push("/game-round")
              : setResetLieModalOpen(true)
          }
        >
          Next round
        </Button>
      ) : (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch({ type: resetGamePreservePlayers });
              history.push("/");
            }}
            style={{ marginBottom: 16 }}
          >
            Reset game
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch({ type: resetGame });
              history.push("/");
            }}
          >
            Reset game and delete players
          </Button>
        </>
      );
    }
  };

  const renderGameOverStuff = () => {
    const winners = getWinners(players);
    let winnerText = "";
    let multipleWinners = "";

    if (winners.length > 1) {
      for (let i = 0; i < winners.length; i++) {
        multipleWinners += winners[i].name;
        multipleWinners += i !== winners.length - 1 ? ", " : ".";
      }
    }

    if (winners.length === 1) {
      winnerText = `The winner is ${winners[0].name}!`;
    } else if (winners.length > 1) {
      winnerText = `The winners are: ${multipleWinners}`;
    }

    return (
      <>
        <Typography variant="h4">Game Over</Typography>
        <Typography>{gameOverReason}</Typography>
        <ScoreBoard players={players} />
        <Typography variant="h5" style={{ margin: "16px 0px" }}>
          {winnerText}
        </Typography>
      </>
    );
  };

  return (
    <>
      <div className="sparseBgContainer">
        <div className="container">
          {previousGuess ? (
            <Typography variant="h6">
              You guessed {previousGuess === FactOrFib.Fact ? "Fact" : "Fib"}{" "}
              and the statement was...
            </Typography>
          ) : (
            <Typography variant="h6">
              You ran out of time the statement was...
            </Typography>
          )}

          {showAnswer && (
            <Typography variant="h3" className="settings-heading">
              {previousStatement?.factOrFib === FactOrFib.Fact
                ? "A Fact!"
                : "A Fib!"}
            </Typography>
          )}
          {showAnswer && explainer()}
          {showAnswer && gameOver && renderGameOverStuff()}
          {/** The winner is... might be worth a function to render game over content, also perhaps having a game over reason */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 8,
            }}
          >
            {renderButtons()}
          </div>
          <Dialog
            open={resetLieModalOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99 }}
          >
            <DialogTitle id="alert-dialog-title">{"Heads up.."}</DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description-one"
                style={{ marginBottom: 0 }}
              >
                Looks like you've just run out of lies that haven't been shown
                on this device.
                <br />
                <br />
                You can either:
                <br />
                - Reset the lies and continue playing (but someone in the group
                may recognise lies)
                <br />
                - End the game here and now.
                <br />
                <br />
                What would you like to do?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  dispatch({ type: resetLies });
                  setResetLieModalOpen(false);
                }}
                color="primary"
                autoFocus
              >
                Reset lies
              </Button>
              <Button
                onClick={() => {
                  dispatch({ type: endGame });
                  setResetLieModalOpen(false);
                }}
                color="secondary"
              >
                End game
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <div className="area" style={{ backgroundColor: getBackgroundColor() }}>
      </div>
    </>
  );
};
