import React, { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { TapToReveal } from "../../components/atoms/TapToReveal";
import { FactOrFib } from "../../components/molecules/FactOrFib";
import {
  endTurn,
  makeGuess,
  revealStatement,
  startGame,
} from "../../store/state/game";
import Countdown from "react-countdown";
import { FactOrFib as FactOrFibType, OnlinePlayer } from "../../models/game";
import { ScoreBoard } from "../../components/organisms/ScoreBoard";
import { AppState } from "../../store/store";
import moment from "moment";
// import { AdBanner } from "../../components/atoms/AdBanner";
import { AccountManagement } from "../../components/organisms/AccountManagement";
import { StoreModal } from "../../components/organisms/StoreModal";
import { getOnlinePlayerById } from "../../helpers/GameHelpers";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import { getGame, leaveGame, setGuess, toggleReady } from "../../services/ApiGameService";
import { APIErrorResponse, GameServiceResponse } from "../../models/responses";
import firebase from 'firebase';
import { updateGame, resetGame as resetOnlineGame, setGameRevealSeen } from "../../store/state/online-game";
import { setToast } from "../../store/state/ui";

import { useInterval } from "../../helpers/useInterval";
import { API_POLLING_ENABLED, API_UPDATE_POLL_TIME } from "../../services/ApiCommon";

import { db } from "../../services/firebase";

// import { AdBreakModal } from "../../components/organisms/AdBreakModal";

interface MainGameScreenProps {
  isOnlineGame?: boolean;
}

export const MainGameScreen = (props: MainGameScreenProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  
  const { isOnlineGame = false } = props;
  const onlineGame = useSelector((x: AppState) => x.onlineGameReducer);
  const onlinePlayers: OnlinePlayer[] = useSelector((x: AppState) => x.onlineGameReducer.players) || [];
  const currentUser = useSelector((x: AppState) => x.userReducer.apiUser);
  const myOnlinePlayer: OnlinePlayer | null = currentUser ? getOnlinePlayerById(onlinePlayers, currentUser?.id) : null;
  const currentPlayer: OnlinePlayer | null = onlinePlayers.find(p => p.user_id === onlineGame.currentPlayerId) || null;
  const firebaseUser = firebase.auth().currentUser;

  const [revealed, setRevealed] = useState(false);

  const players = useSelector((x: AppState) => x.gameReducer.players);
  const currentOnlineStatement = onlineGame.currentStatement;
  const currentLocalStatement = useSelector(
    (x: AppState) => x.gameReducer.currentStatement
  );
  const currentStatement = isOnlineGame ? currentOnlineStatement : currentLocalStatement;
  const playerInd = useSelector(
    (x: AppState) => x.gameReducer.currentPlayerIndex
  );
  const localRound = useSelector((x: AppState) => x.gameReducer.round);
  const onlineRound = useSelector((x: AppState) => x.onlineGameReducer.round);
  const round: number = isOnlineGame ? onlineRound : localRound;
  const localTurnInProgress = useSelector(
    (x: AppState) => x.gameReducer.turnInProgress
  );
  const onlineTurnInProgress = onlineGame.turnInProgress;
  const turnInProgress = isOnlineGame ? onlineTurnInProgress : localTurnInProgress;
  const settings = useSelector((x: AppState) => x.gameReducer.settings);
  const gameOver = useSelector((x: AppState) => x.gameReducer.gameOver);
  const onlineGameOver = useSelector((x: AppState) => x.onlineGameReducer.gameOver);

  const [turnEndTime, setTurnEndTime] = useState<moment.Moment | null>(null); //useSelector((x: AppState) => x.gameReducer.turnEndTime);
  // const [showAdModal, setShowAdModal] = useState(false);

  const onPlayerReady = async () => {
    if (firebaseUser) {
      await toggleReady(
        onlineGame,
        firebaseUser,
        (data: GameServiceResponse) => {
          dispatch({ type: updateGame, payload: data });
        },
        (error: APIErrorResponse) => {
          dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        }
      );
    }
  }

  const updateGameState = async () => {
    onlineGame.id && firebaseUser && (
      await getGame(
        onlineGame.id,
        firebaseUser,
        (data: GameServiceResponse) => {
          dispatch({ type: updateGame, payload: data });
        },
        (error: APIErrorResponse) => {
          dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        }
      )
    )
  }

  useInterval(() => API_POLLING_ENABLED && updateGameState, API_UPDATE_POLL_TIME );

  const handleExit = async () => {
    // Call leave game endpoint if online
    history.push('/')

    if (firebaseUser) {
      await leaveGame(
        onlineGame,
        firebaseUser,
        () => {
          dispatch({ type: resetOnlineGame });
        },
        (error: APIErrorResponse) => {
          dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        }
      )
    }
  }

  useEffect(() => {
    if (!isOnlineGame && !players.length) history.push("/");
    if (gameOver) history.push("answer-reveal");
    if (onlineGameOver) history.push("online-answer-reveal")

    // If current statement is null, this is a new game
    if (!isOnlineGame && !turnInProgress && currentStatement == null) {
      dispatch({ type: startGame });
    }

    // // On every 3rd round...
    // if (!showAdModal && round % 3 === 0) {
    //   setShowAdModal(true);
    // }
  });

  // Used to ignore first 
  let initState = true;
  useEffect(() => {
    if (isOnlineGame && onlineGame.previousStatement && !onlineGame.gameRevealSeen && !onlineGame.turnInProgress) {
      history.push("/online-answer-reveal");
    }
    if (onlineGame.turnInProgress) {
      dispatch({ type: setGameRevealSeen, payload: false });
    }

    // If we have a game, subscribe to player action updates
    let unsubscribe: undefined | (() => void) = undefined;
    if (onlineGame.id) {
      unsubscribe = db.collection("game_logs").doc(onlineGame.id)
        .onSnapshot((doc) => {
          // Ignore the first triggering of this for initial state
          if (initState) {
            initState = false;
          } else {
            updateGameState();
          }
        });
    }
      
    return () => {
      unsubscribe && unsubscribe();
    }
  }, [onlineGame, isOnlineGame])

  useEffect(() => {
    if (settings?.maxMinutesPerTurn) {
      if (turnInProgress && !turnEndTime) {
        setTurnEndTime(moment().add(settings.maxMinutesPerTurn, "minutes"));
      }

      // End turn when time expires
      if (!isOnlineGame && turnInProgress && turnEndTime) {
        // duration in ms
        let timeoutDuration = moment().diff(turnEndTime);
        if (timeoutDuration < 0) timeoutDuration = timeoutDuration * -1;
        setTimeout(() => {
          setRevealed(false);
          setTurnEndTime(null);
          dispatch({ type: endTurn });
          history.push("/answer-reveal");
        }, timeoutDuration);
      }
    }
  }, [
    turnInProgress,
    turnEndTime,
    dispatch,
    settings?.maxMinutesPerTurn,
    history,
  ]);

  const setPlayerGuess = async (guess: boolean) => {
    if (firebaseUser) {
      await setGuess(
        guess,
        onlineGame,
        firebaseUser,
        (data: GameServiceResponse) => {
          dispatch({ type: updateGame, payload: data });
        },
        (error: APIErrorResponse) => {
          dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        }
      )
    }
  }

  const getCurrentPlayerName = () => {
    if (!isOnlineGame) {
      return players[playerInd].name + "'s";
    } else {
      if (currentPlayer?.user_id === myOnlinePlayer?.user_id) return 'your'; 
      return currentPlayer?.name +  "'s" || '';
    }
  }

  // @ts-ignore
  const countdownRender = ({ hours, minutes, seconds, completed }) => {
    if (!completed) {
      return (
        <span>
          <Typography
            variant="h5"
            style={{
              color: minutes > 0 ? "#77dd77" : "	#DC143C",
              textShadow: "2px 1px #000",
            }}
          >
            {minutes}m {seconds}s
          </Typography>
        </span>
      );
    } else {
      return <span>Time up!</span>;
    }
  };

  return (
    <div>
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
      {players.length || onlinePlayers.length ? (
        <>
          <div className="sparseBgContainer">
            <div className="container">
              <h1 className="settings-heading" style={{ fontSize: 16 }}>
                Round {round + 1}...
              </h1>
              {/* {!turnInProgress && ( */}
                <ScoreBoard
                  players={isOnlineGame ? onlinePlayers : players}
                  isOnlineGame={isOnlineGame}
                  myPlayer={myOnlinePlayer}
                  currentPlayer={currentPlayer}
                />
              {/* )} */}
              <h1 className="settings-heading">
                It's {getCurrentPlayerName()} turn to read a statement...
              </h1>
              {
                !turnInProgress && !!currentStatement?.text && (
                  <p style={{ fontSize: 16, maxWidth: 500 }}>
                    Only you can see the statement at the moment, press ready to reveal and read
                    to other players.
                  </p>
                ) 
              }
              <TapToReveal
                preRevealText={
                  !isOnlineGame ?
                    "Tap to reveal statement..."
                  :
                    "When " + getCurrentPlayerName() + " ready, the statement will appear here..."
                }
                revealContent={
                  <Typography>
                    {currentStatement?.text ?? "loading.."}
                  </Typography>
                }
                onReveal={() => dispatch({ type: revealStatement })}
                revealed={revealed || !!currentStatement?.text}
                setRevealed={(value: boolean) => setRevealed(value)}
                isOnlineGame={isOnlineGame}
              />
              {!isOnlineGame && turnEndTime && turnInProgress && (
                <>
                  <Typography variant="h6" style={{ marginTop: 16 }}>
                    Time remaining
                  </Typography>
                  <Countdown
                    date={turnEndTime.toDate()}
                    renderer={countdownRender}
                  />
                </>
              )}
              <div style={{ marginTop: 16 }} />
              {/* Local buttons */}
              {turnInProgress && !isOnlineGame && (
                <FactOrFib
                  introText="The team agrees this is a..."
                  onFactBtnClick={() => {
                    dispatch({ type: makeGuess, payload: FactOrFibType.Fact });
                    dispatch({ type: endTurn });
                    setRevealed(false);
                    history.push("/answer-reveal");
                  }}
                  onFibBtnClick={() => {
                    dispatch({ type: makeGuess, payload: FactOrFibType.Fib });
                    dispatch({ type: endTurn });
                    setRevealed(false);
                    history.push("/answer-reveal");
                  }}
                />
              )}
              {/* Online player buttons */}
              {turnInProgress && isOnlineGame && myOnlinePlayer?.user_id !== currentPlayer?.user_id && (
                <FactOrFib
                  introText="I think this is a..."
                  onFactBtnClick={() => setPlayerGuess(true)}
                  onFibBtnClick={() => setPlayerGuess(false)}
                />
              )}
              {
                isOnlineGame && (
                  <>
                    {
                      !turnInProgress && !!currentStatement?.text && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            onPlayerReady();
                          }}
                          style={{ marginRight: 8, marginTop: 32 }}
                          endIcon={!myOnlinePlayer || !myOnlinePlayer.ready ? <ThumbUpIcon /> : <ThumbDownIcon />}
                        >
                          {!myOnlinePlayer || !myOnlinePlayer.ready ? 'Ready' : 'Not ready'}
                        </Button>
                      )
                    }
                    <Button
                      component={ Link }
                      to="/"
                      variant="contained"
                      onClick={() => handleExit()}
                      color="secondary"
                      style={{ marginTop: 16 }}
                    >
                      Leave
                    </Button>
                  </>
                )
              }
              {/* <AdBanner currentPath="main-game-screen" /> */}
              <AccountManagement />
              <StoreModal showButton={false} />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <AdBreakModal
        modalDurationSeconds={10}
        showModal={showAdModal}
        handleClose={() => setShowAdModal(false)}
      /> */}
    </div>
  );
};
