import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core"
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from "react-router-dom";
import LockIcon from '@material-ui/icons/Lock';
import { AccountManagement } from "../components/organisms/AccountManagement";
import { AdBanner } from "../components/atoms/AdBanner";
import { StoreModal } from "../components/organisms/StoreModal";
import { IS_LOCAL } from "../services/ApiCommon";
import { useSelector } from "react-redux";
import { AppState } from "../store/store";
import { ONLINE_MODE_ENABLED } from "../services/featureToggles";

import discordLogo from "../assets/images/discordBubble.png"


const useStyles = makeStyles({
  emphasise: {
    fontWeight: 600,
    color: "#000",
    letterSpacing: 0.9,
    initialLetter: 2,
  },
  number: {
    fontSize: 22
  },
});

export const Landing = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalContentKey, setModalContentKey] = useState("login");
  // fireBaseUser can be null
  const currentUser = useSelector((x: AppState) => x.userReducer.firebaseUser);

  const handleClickOpen = () => {
    setModalContentKey("how-it-works");
    setOpen(true);
  };

  const handleClose = () => {
    setModalContentKey("else");
    setOpen(false);
  };

  const getModalContent = (key: string) => {
    return (<>
      <DialogTitle id="alert-dialog-title">{"Fact or Fib"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description-one" style={{marginBottom: 0}}>
          The party game of porkies, deception, and learning cool and weird stuff about your friends.<br /><br />
        </DialogContentText>
        <DialogTitle id="subtitle-one" style={{padding: 0, marginBottom: 8}}>{"How it works"}</DialogTitle>

        <DialogContentText id="alert-dialog-description-two">
          <span className={classes.emphasise}><span className={classes.number}>1.</span> Each player adds facts about themselves</span> that other players wouldn't necessarily know were true, e.g. secret hobbies, a weird celebrity encounter, tv appearence etc.<br /><br />
          <span className={classes.emphasise}><span className={classes.number}>2.</span> Each player takes a turn reading a statement</span> which will either be one of their given facts, or a randomly chosen lie.<br /><br />
          <span className={classes.emphasise}><span className={classes.number}>3.</span> Players earn points for successfully deceiving other players,</span> convincing the group that their lies are true or truths are false.
          <br /><br />By default, the game ends when a player runs out of truths, or all the random lies have been used in a game.
        </DialogContentText>

        <DialogTitle style={{padding: 0, marginBottom: 8}}>{"Questions & Tips"}</DialogTitle>

        <DialogContentText>
        <span className={classes.emphasise}>Tip for writing truths</span>
        <br />Try to keep your truths as generic as possible, e.g. instead of <b>"I once met David Tenant on holiday in Cornwall"</b>, go with something like <b>"I met a celebrity on holiday"</b>.  This helps your truths blend in better with the generated lies.<br /><br />
        </DialogContentText>

        <DialogContentText>
        <span className={classes.emphasise}>Some of the people playing know each other too well...</span>
        <br />You could divide into teams, grouping people who know each other too well together.  E.g. couples vs couples tournament.<br /><br/>
        </DialogContentText>

        <DialogContentText>
        <span className={classes.emphasise}>What if a generated lie is true?</span>
        <br />If a lie you're given is actually true, make up a story and don't answer any questions truthfully.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Cool, let's go...
        </Button>
      </DialogActions>
    </>);
  }

  return (
    <div className="App">
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
      <div className="container">
        <h1>
          Fact Or Fib
        </h1>
        <h4 className="beta">BETA</h4>
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>How it works</Button>
        {IS_LOCAL || ONLINE_MODE_ENABLED ? (
          <>
            <Button
              component={ Link }
              to="/join-create"
              variant="contained"
              color="primary"
              style={{ marginTop: 16 }}
              disabled={!currentUser}
            >
              Play Online
            </Button>
            {!currentUser && (
              <p className="smallprint">Please sign in to play online</p>
            )}
          </>
        ) : (
          <>
            <Button variant="contained" disabled style={{ paddingRight: 8 }}>Play Online <LockIcon style={{ color: "grey", height: 12 }} /></Button>
            <p className="smallprint">Online mode coming soon...</p>
          </>
        )}
        <Button
          variant="outlined"
          color="primary"
          style={{ marginTop: 16 }}
          href="https://jagetech.com/fact-or-fib/index.html"
          target="_blank"
        >
          Visit site
        </Button>

        {/* // TODO:  Fix regression */}
        {/* <Button
          // component={ Link }
          // to="/player-setup"
          variant="contained"
          color="secondary"
          style={{ marginTop: 16 }}
          disabled
        >
          Play on this device <LockIcon style={{ color: "grey", height: 12 }} />
        </Button> */}
        <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => window.open("https://discord.gg/xGG39HXKKJ")}>
          <img src={discordLogo} alt="Discord" />
        </IconButton>
        <p style={{ fontSize: 14, margin: 0, maxWidth: 250 }}>Find games, suggest features, and report bugs on the Discord channel</p>
        <AdBanner currentPath="landing" />
        <AccountManagement />
        <StoreModal />
      </div>
      <div className="footer">
          <p className="smallprint">v1.2.1</p>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {getModalContent(modalContentKey)}
      </Dialog>
    </div>
  );
}
