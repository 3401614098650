import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  row: {
    display: 'flex'
  },
  col: {
    display: 'flex',
    flexDirection: 'column'
  }
});

interface CardProps {
  productId: string,
  productTitle: string,
  productPrice: string,
  productDescription: string,
  isOwned: boolean,
  buttonText?: string,
  onClick: () => void
}

export default function ProductCard(props: CardProps) {
  const classes = useStyles();
  const {
    // productId,
    productTitle,
    productPrice,
    productDescription,
    isOwned,
    buttonText="Gimme",
    onClick
  } = props;

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent style={{ textAlign: 'start' }}>
        <Typography variant="h5" component="h2">
          {productTitle}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {!isOwned ? productPrice : 'You already own this item.'}
        </Typography>
        <Typography variant="body2" component="p">
          {productDescription}
        </Typography>

        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button
            size="small"
            onClick={() => onClick()}
            disabled={isOwned}
          >
            {!isOwned ? buttonText : 'Owned'}
          </Button>
        </CardActions>
      </CardContent>
      
    </Card>
  );
}
