import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UiState } from "../../models/ui";
const initialState: UiState = {
    storeModalOpen: false,
    profileModalOpen: false,
    // either info, error, warning, or success
    toastSeverity: "info",
    toastMessage: null,
    showToast: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    toggleStoreModalOpen: (state) => {
      state.profileModalOpen = false;
      state.storeModalOpen = !state.storeModalOpen;
    },
    toggleProfileModalOpen: (state) => {
      state.storeModalOpen = false;
      state.profileModalOpen = !state.profileModalOpen;
    },
    setToast: (state, action) => {
      const { message, severity } = action.payload;
      state.toastSeverity = severity;
      state.toastMessage = message;
      state.showToast = true;
    },
    resetToast: (state) => {
      state.showToast = false;
      state.toastMessage = null;
    },
  },
});

export const {
  toggleStoreModalOpen,
  toggleProfileModalOpen,
  setToast,
  resetToast,
} = uiSlice.actions;
