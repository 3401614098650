import React, { ReactNode, useState, useEffect } from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetGame, updateGameSettings } from "../../store/state/game";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import { GameSettings, OnlineGameSettings } from "../../models/game";
import { AppState } from "../../store/store";
import SettingsIcon from '@material-ui/icons/Settings';
import { AdBanner } from '../../components/atoms/AdBanner';
import { AccountManagement } from "../../components/organisms/AccountManagement";
import { StoreModal } from "../../components/organisms/StoreModal";
import { setToast, toggleStoreModalOpen } from '../../store/state/ui';
import { updateGame } from "../../services/ApiGameService";
import { updateGame as updateGameState } from "../../store/state/online-game";
import { APIErrorResponse, GameServiceResponse } from "../../models/responses";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
});

interface GameSettingsProps {
  nextPagePath?: string;
  isOnlineGame?: boolean;
  nextPageButtonText?: string;
}


interface Setting {
  name: string,
  description: string,
  field: ReactNode,
}

export const GameSettingsPage = (props: GameSettingsProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    const {
      nextPagePath = '/game-round',
      nextPageButtonText='Start',
      isOnlineGame = false
    } = props;

    const gameSettings = useSelector((x: AppState) => x.gameReducer.settings);
    const ownedProducts = useSelector((x: AppState) => x.userReducer.apiUser?.expansions_owned);
    const onlineGame = useSelector((x: AppState) => x.onlineGameReducer);
    const currentUser = useSelector((x: AppState) => x.userReducer.firebaseUser);

    const offlinePremium = ownedProducts?.includes('premium') || ownedProducts?.includes('premium_temp');

    const editingEnabled = offlinePremium;

    const [numRounds, setNumRounds] = useState<string | undefined>(gameSettings.numRounds?.toString());
    const [winningPoints, setWinningPoints] = useState<string | undefined>(gameSettings.winningNumPoints?.toString());
    const [timePerTurn, setTimePerTurn] = useState<string | undefined>(gameSettings.maxMinutesPerTurn?.toString());
    const [truthChange, setTruthChange] = useState<string | undefined>(gameSettings.truthChance.toString());
    const [validationText, setValidationText] = useState<string>("");
    
    const onStart = async () => {
      // Check truths chance is not undefined or empty
      if (!truthChange || truthChange === "" || parseInt(truthChange) <= 0) {
        setValidationText("A truth chance must be set and must be above zero");
        return;
      }

      // Update state with new game settings (if offline premium user)
      if (!isOnlineGame && editingEnabled) {
        const newSettings: GameSettings = {
          truthChance: parseInt(truthChange),
          numRounds: numRounds ? parseInt(numRounds) : undefined,
          winningNumPoints: winningPoints ? parseInt(winningPoints) : undefined,
          maxMinutesPerTurn: timePerTurn ? parseInt(timePerTurn) : undefined,
        };
        dispatch({ type: updateGameSettings, payload: newSettings });
      }

      if (currentUser && onlineGame.id && isOnlineGame && editingEnabled) {
        const newSettings: OnlineGameSettings = {
          truth_chance: parseInt(truthChange),
          num_rounds: numRounds ? parseInt(numRounds) : undefined,
          winning_num_points: winningPoints ? parseInt(winningPoints) : undefined,
          max_minutes_per_turn: timePerTurn ? parseInt(timePerTurn) : undefined,
        }
        await updateGame(
          newSettings,
          onlineGame.id,
          currentUser,
          (data: GameServiceResponse) => {
            dispatch({ type: updateGameState, payload: data });
          },
          (error: APIErrorResponse) => {
            dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
          }
        )
      }

      history.push(nextPagePath);
    }

    const handleReset = () => {
      if (isOnlineGame) return;
      dispatch({ type: resetGame });
      history.push('/player-setup');
    }

    useEffect(() => {
      // Handle game screen routing for non-host players
      if (onlineGame.gameStarted) {
        history.push('online-game-round');
      }
    }, [onlineGame, history])

    const settings: Setting[] = [
      {
        name: "Number of rounds",
        description: "Leave blank for unlimited.",
        field: <TextField
                  type="number"
                  variant="outlined"
                  value={numRounds}
                  onChange={e => setNumRounds(e.target.value)}
                  disabled={!offlinePremium}
                />
      },
      {
        name: "Winning points",
        description: "Game will end when a player gets this  number of points, leave blank for unlimited.",
        field: <TextField
                  type="number"
                  variant="outlined"
                  value={winningPoints}
                  onChange={e => setWinningPoints(e.target.value)}
                  disabled={!offlinePremium}
                />
      },
      {
        name: "Max time per turn",
        description: "If no decision is made in this time, the player being questioned gets the point. Leave blank for unlimited",
        field: <TextField
                  type="number"
                  variant="outlined"
                  InputProps={{ endAdornment: <Typography variant="caption">min</Typography> }}
                  value={timePerTurn}
                  onChange={e => setTimePerTurn(e.target.value)}
                  disabled={!offlinePremium}
                />
      },
      {
        name: "Truth chance",
        description: "Likelihood of a player getting one of their truths to read",
        field: <TextField
                  type="number"
                  variant="outlined"
                  value={truthChange}
                  onChange={e => setTruthChange(e.target.value)}
                  InputProps={{ endAdornment: <Typography variant="caption">%</Typography> }}
                  disabled={!offlinePremium}
                />
      },
    ]

    return (
      <div className="settingsContainer" style={{ paddingBottom: 80 }}>
        <AccountManagement />
        <StoreModal />
        <div className="settingsTitleContainer">
          <SettingsIcon style={{ marginRight: 8, fontSize: 40, color: "#3f51b5" }}/>
          <h1 className="settings-heading">
            Game Settings
          </h1>
        </div>
        {!offlinePremium ? <h3>Unlock settings with <span onClick={() => dispatch({ type: toggleStoreModalOpen })} style={{ color: 'rgba(63,81,181,1)', cursor: 'pointer', textDecoration: 'underline' }}>offline premium</span></h3> : null}

        <TableContainer component={Paper} style={{ maxWidth: 700 }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Game setting</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {settings.map(s => {
                if (isOnlineGame && s.name === 'Max time per turn') return;

                return (
                  <TableRow key={s.name}>
                    <TableCell>
                      <Typography>{s.name}</Typography>
                      <Typography variant="caption">{s.description}</Typography>
                    </TableCell>
                    <TableCell style={{ minWidth: 80 }}>
                      {s.field}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {validationText && <Typography className={"validation-text"}>{validationText}</Typography>}
        <div className="buttonRow" style={{ marginBottom: 16, marginTop: 32 }}>
          <Button
            variant="contained"
            onClick={() => onStart()}
            color="primary"
            style={{ marginRight: 8 }}
          >
            {nextPageButtonText}
          </Button>
          {
            !isOnlineGame && (
              <Button
                variant="contained"
                onClick={() => handleReset()}
                color="secondary"
                style={{ marginRight: 8 }}
              >
                Reset
              </Button>
            )
          }
          <Button
            component={ Link }
            to="/"
            variant="contained"
            onClick={() => history.goBack()}
            color="secondary"
          >
            Back
          </Button>
      </div>
      <AdBanner currentPath="game-settings" />
      </div>
    )
}
