import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Game, GameSettings } from "../../models/game";
import { generateLieStatements } from "../data";
import { listHasUnusedStatement } from "../../helpers/GameHelpers";
import { gameIsOver, getStatement } from "../../helpers/GameLogic";

const initialState: Game = {
  id: 0,
  players: [],
  currentPlayerIndex: 0,
  currentStatement: null,
  previousStatement: null,
  lies: generateLieStatements(),
  guess: null,
  previousGuess: null,
  turnInProgress: false,
  gameOver: false,
  allLiesSeen: false,
  round: 0,
  settings: {
    truthChance: 30,
    numRounds: 6,
    maxMinutesPerTurn: 5,
    winningNumPoints: 3
  } as GameSettings,
};

export const gameSlice = createSlice({
  name: "game",
  initialState: initialState,
  reducers: {
    startGame: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      
      // Get a statement and reset lies first if they've all been seen
      state.currentStatement = getStatement(state, true);
    },
    revealStatement: (state) => {
      state.turnInProgress = true;
    },
    makeGuess: (state, action) => {
      state.guess = action.payload;
    },
    endTurn: (state) => {
      state.turnInProgress = false;

      // If the guess was incorrect or there was no guess made, current player gets
      // a point
      if (state.guess !== state.currentStatement?.factOrFib || !state.guess) {
        state.players[state.currentPlayerIndex].points += 1;
      }

      // reset guess
      state.previousGuess = state.guess;
      state.guess = null;

      // Set prev statement
      state.previousStatement = state.currentStatement;

      // Check to see if the game is over (all lies used or all player truths used)
      const gameOverReason = gameIsOver(state);
      if (gameOverReason) {
        state.gameOver = true;
        state.gameOverReason = gameOverReason;
      }

      // Check to see if all the lies have been seen
      if (!listHasUnusedStatement(state.lies)) state.allLiesSeen = true;

      // Set the player turn index to next player
      // Set regardless of game over so that game over screen reads properly
      state.currentPlayerIndex !== state.players.length - 1
        ? (state.currentPlayerIndex += 1)
        : (state.currentPlayerIndex = 0);

      if (!state.gameOver) {
        state.currentStatement = getStatement(state);
        state.round += 1;
      }
    },
    updatePlayer: (state, action) => {
      const { payload: newPlayer } = action;
      const playerInd =
        state.players.length > 0
          ? state.players.findIndex((p) => p.id === newPlayer.id)
          : null;

      if (playerInd === null || playerInd === -1) {
        state.players.push(newPlayer);
      } else {
        state.players[playerInd] = newPlayer;
      }
    },
    removePlayer: (state, action) => {
      const { payload: playerToRemove } = action;
      state.players = state.players.filter((p) => p.id !== playerToRemove.id);
    },
    addPlayerTruth: (state, action) => {
      const { playerId, truth } = action.payload;
      const targetPlayerIndex = state.players.findIndex(
        (p) => p.id === playerId
      );
      state.players[targetPlayerIndex].truths.push(truth);
    },
    removePlayerTruth: (state, action) => {
      const { playerId, truth } = action.payload;
      const targetPlayerIndex = state.players.findIndex(
        (p) => p.id === playerId
      );
      state.players[targetPlayerIndex].truths.filter(
        (t) => t.text !== truth.text
      );
    },
    endGame: (state) => {
      state.gameOver = true;
    },
    resetGame: (state) => {
      state.id = 0;
      state.players = [];
      state.currentPlayerIndex = 0;
      state.currentStatement = null;
      state.previousStatement = null;
      state.guess = null;
      state.previousGuess = null;
      state.turnInProgress = false;
      state.gameOver = false;
      state.gameOverReason = null;
      state.round = 0;
      state.settings = initialState.settings;
    },
    resetGamePreservePlayers: (state) => {
      state.id = 0;
      state.currentPlayerIndex = 0;
      state.currentStatement = null;
      state.previousStatement = null;
      state.guess = null;
      state.previousGuess = null;
      state.turnInProgress = false;
      state.gameOver = false;
      state.gameOverReason = null;
      state.round = 0;
      state.settings = initialState.settings;

      // Reset used status on all truths and reset players points
      state.players.forEach((p, i) => {
        state.players[i].points = 0;
        for (let j = 0; j < p.truths.length; j++) {
          state.players[i].truths[j].used = false;
        }
      });
    },
    resetLies: (state) => {
      state.lies = generateLieStatements();
      state.allLiesSeen = false;
    },
    updateGameSettings: (state, action: PayloadAction<GameSettings>) => {
      state.settings = action.payload;
    },
  },
});

export const {
  startGame,
  revealStatement,
  makeGuess,
  endTurn,
  updatePlayer,
  removePlayer,
  addPlayerTruth,
  removePlayerTruth,
  updateGameSettings,
  resetGame,
  resetGamePreservePlayers,
  endGame,
  resetLies,
} = gameSlice.actions;
