import React from 'react';
import { Landing } from './pages/Landing';
// https://medium.com/@ipenywis/intro-to-react-router-for-beginners-multiple-page-apps-461f4729bd3f
import { PlayerManagement } from './pages/gameSetup/PlayerManagement';
import { GameSettingsPage } from './pages/gameSetup/GameSettings';
import { OnlineJoinOrCreate } from './pages/gameSetup/OnlineJoinOrCreate';
import './App.css';
import {
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import ReactGA from 'react-ga';
import { MainGameScreen } from './pages/gamePages/MainGameScreen';
import { AnswerReveal } from './pages/gamePages/AnswerReveal';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './store/store';
import { resetToast } from './store/state/ui';
import { OnlineAnswerReveal } from './pages/gamePages/online/OnlineAnswerReveal';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
  let location = useLocation();
  const errorText = useSelector((x: AppState) => x.uiReducer.toastMessage);
  const showError = useSelector((x: AppState) => x.uiReducer.showToast);
  const severity = useSelector((x: AppState) => x.uiReducer.toastSeverity);

  const dispatch = useDispatch();

  React.useEffect(() => {
    ReactGA.initialize('UA-198167144-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  const onErrorClose = () => {
    dispatch({ type: resetToast });
  }

  return (
    <>
      <Switch>
        {/*All our Routes go here!*/}
        <Route exact path="/" component={Landing} />
        <Route path="/player-setup" component={PlayerManagement} />
        <Route
          path="/online-player-setup"
          render={() =>
            <PlayerManagement
              isOnlineGame
              nextPagePath='/online-game-round'
              nextPageButtonText='Start'
            />
          }
        />
        <Route path="/game-settings" component={GameSettingsPage} />
        <Route
          path="/online-game-settings"
          render={
            () =>
                <GameSettingsPage
                  isOnlineGame
                  nextPagePath='/online-player-setup'
                  nextPageButtonText='Player setup'
                />
          }
        />
        <Route path="/game-round" component={MainGameScreen} />
        <Route
          path="/online-game-round"
          render={() => <MainGameScreen isOnlineGame />}
        />
        <Route path="/answer-reveal" component={AnswerReveal} />
        <Route path="/online-answer-reveal" component={OnlineAnswerReveal} />
        <Route path="/join-create" component={OnlineJoinOrCreate} />

        {/* Not found page */}
        <Route exact component={Landing} />
      </Switch>

      <Snackbar open={showError} autoHideDuration={6000} onClose={onErrorClose}>
        <Alert onClose={onErrorClose} severity={severity}>
          {errorText}
        </Alert>
      </Snackbar>
    </>
  );
}

export default App;
