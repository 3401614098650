import { FactOrFib, Statement } from "../models/game";
const lieSeeds = [
  "I was a tree in a nativity play",
  "I was chased by a turtle when I was on crutches",
  "Every time I’ve gone ice skating I’ve broken my leg",
  "I was nearly killed by a coconut",
  "My mum has had tea with the Queen three times",
  "Once I cut a friend's hair and used it to stuff my bear",
  "I’ve been lost at sea",
  "I thought I was colour blind for years but they had just got the test wrong",
  "I stole the baby Jesus at our school Christmas production",
  "I used to collect pigs",
  "I always use a fake name eating alone at restaurants",
  "I own red leather pants and cowboy boots.",
  "I once sent a very inappropriate text to my boss by mistake",
  "I’ve been trodden on by a horse and chased by a cow",
  "I’ve seen one of my own bones",
  "I’m related to Boris Johnson",
  "I stop myself being carsick by holding a lemon",
  "When I was a kid I tried to teach my dog to talk.",
  "I’ve been in a flash mob",
  "I used to be obsessed with photographing boardwalks",
  "My father was in the Guinness Book of Records for push ups",
  "I slept two nights in an airport after I missed a flight",
  "I was deaf until I was 6",
  "I choked on a jellybean and ended up in A&E",
  "I’ve swallowed a goldfish",
  "I killed a chicken with a spade",
  "I've eaten dog food",
  "I've killed a chicken with a spade",
  "My grandmother froze the family cat",
  "I’ve completed a professional eating contest",
  "I can hold my breath for two minutes",
  "I've done a course in taxidermy",
  "I did a course in clowning",
  "I was picked up by a drunk driver hitch hiking",
  "I was born with two webbed toes",
  "I wore the same jacket every day in high school",
  "I speak four languages",
  "I have been in and won a professional eating contest.",
  "I have won an art contest.",
  "I hate the color orange.",
  "I was interviewed on the news once.",
  "I have over 100 selfies on my phone, but have never posted any of them on social media.",
  "I once met the Queen",
  "I have a fear of uber and ola drivers",
  "The first time I had sex I farted loudly part way through",
  "I once managed to shut myself in a walk in freezer for half an hour",
  "I once woke up in a bush in my underwear",
  "I have a photographic memory",
  "I have broken my arm four times",
  "I have written and self published three short stories online under an alias",
  "I once wrote a play about hard boiled eggs",
  "I once got stuck in a lift for six hours",
  "I own six robes",
  "I've broken both wrists and both ankles.",
  "I used to be deathly afraid of elevators.",
  "I've done stand-up exactly two times.",
  "I've met Tom Cruise.",
  "I was born with a tail.",
  "I brush my teeth four times a day.",
  "I never use public restrooms.",
  "I'm allergic to strawberries.",
  "I practice Buddhism.",
  "I can put both legs behind my head and walk with my arms.",
  "My nickname in primary school was Squishy",
  "I have a fear of anything that jumps.",
  "I can play the flute with my nose.",
  "I still sleep with a stuffed animal.",
  "I have been to a nude beach.",
  "I had a dog named Scruff McGruff.",
  "I can sense ghosts.",
  "I collect rocks.",
  "I was in a marching band.",
  "I once shaved my fathers legs when he was sleeping",
];

function shuffle(array: Statement[]) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const generateLieStatements = () => {
  const lieStatements: Statement[] = [];
  let lieIndex = 0;
  lieSeeds.forEach((l) => {
    lieStatements.push({
      id: lieIndex,
      text: l,
      used: false,
      factOrFib: FactOrFib.Fib,
    });
    lieIndex += 1;
  });
  return shuffle(lieStatements);
};
