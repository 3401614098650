import { Typography, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppState } from "../../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { OnlinePlayer } from "../../../models/game";
import { ScoreBoard } from "../../../components/organisms/ScoreBoard";
import { getOnlinePlayerById } from "../../../helpers/GameHelpers";
import firebase from 'firebase';
import { getGame, leaveGame } from "../../../services/ApiGameService";
import { resetGame as resetOnlineGame, setGameRevealSeen, updateGame } from "../../../store/state/online-game";
import { APIErrorResponse, GameServiceResponse } from "../../../models/responses";
import { setToast } from "../../../store/state/ui";

export const OnlineAnswerReveal = () => {
  const dispatch = useDispatch();

  const {
    players,
    previousStatement,
    winners,
    gameOver,
    gameOverReason,
  } = useSelector((x: AppState) => x.onlineGameReducer);
  const history = useHistory();
  const [showAnswer, setShowAnswer] = useState(false);
  const currentUser = useSelector((x: AppState) => x.userReducer.apiUser);
  const myOnlinePlayer: OnlinePlayer | null = currentUser ? getOnlinePlayerById(players, currentUser?.id) : null;
  const previousGuess: boolean | null = myOnlinePlayer?.previous_guess || null;
  const onlineGame = useSelector((x: AppState) => x.onlineGameReducer);

  const firebaseUser = firebase.auth().currentUser;


  const getBackgroundColor = () => {
    if (!showAnswer) return "#4e54c8";
    if (previousStatement?.factual !== undefined)
      return getStatementColour(previousStatement.factual);
    return "#4e54c8";
  };

  const getStatementColour = (factual: boolean) => {
    if (factual) {
      return "#77dd77";
    } else {
      return "#ff6961";
    }
  };

  const handleExit = async () => {
    // Leave game if it's not over
    if (firebaseUser && !gameOver) {
      await leaveGame(
        onlineGame,
        firebaseUser,
        () => {
          dispatch({ type: resetOnlineGame });
        },
        (error: APIErrorResponse) => {
          dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        }
      )
    }

    if (gameOver) {
      dispatch({ type: resetOnlineGame });
    }

    history.push("/");
  }

  useEffect(() => {
    setTimeout(() => {
      setShowAnswer(true);
    }, 2000);
  });

  const updateGameState = async () => {
    onlineGame.id && firebaseUser && (
      await getGame(
        onlineGame.id,
        firebaseUser,
        (data: GameServiceResponse) => {
          dispatch({ type: updateGame, payload: data });
        },
        (error: APIErrorResponse) => {
          dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        }
      )
    )
  }

  const renderButtons = () => {
    if (showAnswer) {
      return !gameOver ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch({ type: setGameRevealSeen, payload: true });
            // We're not subscribed to events at this stage so we call for an update
            // before we move back to subscribing
            updateGameState();
            history.push("/online-game-round");
          }}
        >
          Next round
        </Button>
      ) : (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleExit()}
          >
            Leave
          </Button>
        </>
      );
    }
  };

  const renderGameOverStuff = () => {
    if (!winners) return;

    let winnerText = "";
    let multipleWinners = "";

    if (winners.length > 1) {
      for (let i = 0; i < winners.length; i++) {
        multipleWinners += winners[i].name;
        multipleWinners += i !== winners.length - 1 ? ", " : ".";
      }
    }

    if (winners.length === 1) {
      winnerText = `The winner is ${winners[0].name}!`;
    } else if (winners.length > 1) {
      winnerText = `The winners are: ${multipleWinners}`;
    }

    return (
      <>
        <Typography variant="h4">Game Over</Typography>
        <Typography>{gameOverReason}</Typography>
        <ScoreBoard players={players} />
        <Typography variant="h5" style={{ margin: "16px 0px" }}>
          {winnerText}
        </Typography>
      </>
    );
  };

  return (
    <>
      <div className="sparseBgContainer">
        <div className="container">
          {console.log(previousGuess)}
          {!!onlineGame.previousPlayerId && !!myOnlinePlayer?.user_id && (
            <Typography variant="h6">
              {
                onlineGame.previousPlayerId !== myOnlinePlayer.user_id && (
                  `You guessed ${myOnlinePlayer?.previous_guess ? "Fact" : "Fib"} and`
                )
              }
              {" "}the statement was...
            </Typography>
          )}

          {showAnswer && (
            <Typography variant="h3" className="settings-heading">
              {previousStatement?.factual
                ? "A Fact!"
                : "A Fib!"}
            </Typography>
          )}
          {showAnswer && onlineGame.explainer}
          {showAnswer && gameOver && renderGameOverStuff()}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 8,
            }}
          >
            {renderButtons()}
          </div>
        </div>
      </div>
      <div className="area" style={{ backgroundColor: getBackgroundColor() }}>
      </div>
    </>
  );
};
