import firebase from "firebase/app";
import { PurchaseVerificationResponse } from "../models/responses";

const validatePurchaseOnBackend = (purchaseToken: string, sku: string, user: firebase.User) => {
    // TODO: Populate this via actual API response, make it async
    // The verification service also populates user data in db for valid purchase.
    // Simple PUT request with a JSON body using fetch
    // const requestOptions = {
    //     method: 'PUT',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ token: purchaseToken, productId: sku, user.uid })
    // };
    // fetch('https://jsonplaceholder.typicode.com/posts/1', requestOptions)
    //     .then(response => response.json())
    //     .then(data => this.setState({ postId: data.id }));
        
    return {
        message: 'Your purchase was successful, thank you and enjoy!',
        statusCode: 200,
        purchaseSuccess: true
    } as PurchaseVerificationResponse;
}

export const makePurchase = async (service: unknown, sku: string, user: firebase.User) => {
    // Uncomment for testing
    //return validatePurchaseOnBackend('test123', sku, user)

    if (!service) return {
        message: 'Purchase failed, no digital goods service',
        statusCode: 500,
        purchaseSuccess: false
    };

    // Define the preferred payment method and item ID
    const paymentMethods = [{
        supportedMethods: "https://play.google.com/billing",
        data: {
            sku: sku,
        }
    }];

    // The "total" member of the paymentDetails is required by the Payment
    // Request API, but is not used when using Google Play Billing. We can
    // set it up with bogus details.
    const paymentDetails = {
        total: {
            label: `Total`,
            amount: {currency: `USD`, value: `0`}
        }
    };

    const request = new PaymentRequest(paymentMethods, paymentDetails);
    try {
        //@ts-ignore
        const paymentResponse = await request.show();
        const {purchaseToken} = paymentResponse.details;
        const verificationResp = validatePurchaseOnBackend(purchaseToken, sku, user);

        // Call backend to validate the purchase.
        if (verificationResp.purchaseSuccess) {
            // Acknowledge using the Digital Goods API. Use ‘onetime' for
            // items that can only be purchased once and ‘repeatable for
            // items that can be purchased multiple times.

            // @ts-ignore
            await service.acknowledge(purchaseToken, 'onetime');

            // Optional: tell the PaymentRequest API the validation was
            // successful. The user-agent may show a "payment successful"
            // message to the user.
            // const paymentComplete = await paymentResponse.complete('success');
            return verificationResp;
        } else {
            // Optional: tell the PaymentRequest API the validation failed.
            // The user agent may show a message to the user.
            // const paymentComplete = await paymentResponse.complete('fail');
            return verificationResp;
        }
    } catch(e) {
        // The purchase failed, and we can handle the failure here.
        // AbortError usually means a user cancellation
        return {
            message: '',
            statusCode: 400,
            purchaseSuccess: false
        } as PurchaseVerificationResponse;
    }
}
