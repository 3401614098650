import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
import { isOnlinePlayer, OnlinePlayer, Player } from "../../models/game";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { AppState } from "../../store/store";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
});

interface ScoreBoardProps {
  players: Player[] | OnlinePlayer[];
  isOnlineGame?: boolean;
  myPlayer?: OnlinePlayer | null;
  currentPlayer?: OnlinePlayer | null;
}

export const ScoreBoard = (props: ScoreBoardProps) => {
  const classes = useStyles();
  const { players, isOnlineGame = false, myPlayer = null, currentPlayer = null } = props;
  const currentPlayerId = useSelector((x: AppState) => x.onlineGameReducer.currentPlayerId);

  const getPlayerGuess = (guess: boolean | null | undefined) => {
    if (guess !== null || undefined) {
      return guess ? 'Fact' : 'Fib';
    }
    return 'Undecided'
  }

  const getName = (player: OnlinePlayer | Player) => {
    let name = player.name;
    if (isOnlinePlayer(player) && myPlayer?.user_id === player.user_id) {
      name += ' (me)';
    }
    return name;
  }

  return (
    <TableContainer component={Paper} style={{ maxWidth: 400, maxHeight: 200 }}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Player</TableCell>
            {
              isOnlineGame && (
                <TableCell style={{ maxWidth: 50 }}>Guess</TableCell>
              )
            }
            <TableCell style={{ maxWidth: 50 }}>Points</TableCell>
            {
              isOnlineGame && (
                <TableCell style={{ maxWidth: 50 }}>Ready?</TableCell>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {players.map((p: Player | OnlinePlayer) => (
            <TableRow key={!isOnlinePlayer(p) ? p.id.toString() : p.user_id}>
              <TableCell>
                <Typography>
                  {
                    currentPlayer && isOnlinePlayer(p) && currentPlayer.user_id === p.user_id ?
                      <span style={{ fontWeight: 800 }}>{getName(p)}</span>
                    :
                      getName(p)
                  }
                  
                </Typography>
              </TableCell>

              {
                isOnlinePlayer(p) && (
                  <TableCell style={{ maxWidth: 50 }}>
                    {
                      // Only render guess if it's not this player's turn right now
                      currentPlayer && isOnlinePlayer(p) && currentPlayer.user_id !== p.user_id && (
                        getPlayerGuess(p.guess)
                      )
                    }
                  </TableCell>
                )
              }

              <TableCell style={{ maxWidth: 50 }}>
                {p.points.toString()}
              </TableCell>

              {
                isOnlinePlayer(p) && currentPlayerId === p.user_id && (
                  p.ready ?
                    <TableCell style={{ maxWidth: 50 }}>
                      <ThumbUpIcon style={{ color: '#7FFF00' }} />
                    </TableCell>
                  :
                  <TableCell style={{ maxWidth: 50 }}>
                    <ThumbDownIcon style={{ color: '#fe7b7c' }} />
                  </TableCell>
                )
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
