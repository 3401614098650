import React from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 300,
    alignItems: "center",
    padding: "16px 8px",
    borderRadius: 10,
    border: "3px solid #ff6961",
    backgroundColor: "#77dd77",
    boxShadow: "5px 8px",
  },
  btnRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  }
});

interface FactOrFibProps {
  introText: string;
  onFactBtnClick: () => void;
  onFibBtnClick: () => void;
}

export const FactOrFib = (props: FactOrFibProps) => {
  const { introText, onFactBtnClick, onFibBtnClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="caption" style={{ marginBottom: 8, fontWeight: 600, letterSpacing: 0.8, fontSize: 15 }}>{introText}</Typography>
      <div className={classes.btnRow}>
        <Button variant="contained" color="primary" onClick={() => onFactBtnClick()}>Fact</Button>
        <Button variant="contained" color="secondary" onClick={() => onFibBtnClick()}>Fib</Button>
      </div>
    </div>
  );
};
