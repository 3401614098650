import { OnlinePlayer, Player, Statement } from "../models/game";

/**
 * Return either a truth or lie based on truth chance.  If we run out of lies or truth that haven't
 * been seen, we return undefined
 *
 * @param lies
 * @param currentPlayer
 * @param truthChance
 * @returns statement object (or undefined if we tried to retrieve a truth or lie but all were used)
 */
export const getStatement = (
  lies: Statement[],
  currentPlayer: Player,
  truthChance: number
) => {
  const rn = Math.floor(Math.random() * 100 + 1);

  if (rn <= truthChance) {
    // Find the next unused player truth, or return undefined if
    // no truth available
    return currentPlayer.truths.find((t) => !t.used);
  } else {
    // Return the next unseen lie
    return lies.find((l) => !l.used);
  }
};

export const listHasUnusedStatement = (statementList: Statement[]) => {
  return statementList.find((t) => !t.used) !== undefined;
};

export const getWinners = (players: Player[]): Player[] => {
  const winners: Player[] = [];

  const highestScoringPlayer = players.reduce((max, player) =>
    max.points > player.points ? max : player
  );
  winners.push(highestScoringPlayer);
  // Add any other players that also have the highest score
  players.forEach((p) => {
    if (
      p.points === highestScoringPlayer.points &&
      p.id !== highestScoringPlayer.id
    )
      winners.push(p);
  });
  
  return winners;
};


export const getOnlinePlayerById = (playerList: OnlinePlayer[], myId: string) => {
  if (!playerList) return null;
  return playerList.filter(p => p.user_id === myId)[0];
}
