import React, { useState } from "react";
import { Button, makeStyles, Typography, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { FactOrFib, Statement, Player } from "../../models/game";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HelpIcon from "@material-ui/icons/Help";

interface EditPlayerProps {
  onSave: (player: Player) => void;
  onCancel: () => void;
  existingPlayers: Player[];
  player?: Player | null;
}

const useStyles = makeStyles({
  existingTruthsCol: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: 16,
    height: 180,
    overflowY: "scroll",
    alignItems: "center",
    padding: "8px 0",
  },
  extistingTruthsRow: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 8,
    width: "85%",
  },
  newTruth: {
    display: "flex",
    marginBottom: 16,
    width: "100%",
  },
  textField: {
    height: 16,
    fontSize: 12,
    width: "100%",
    "&::placeholder": {
      fontSize: 12,
    },
  },
  root: {
    width: 80,
  },
});

export const EditPlayer = (props: EditPlayerProps) => {
  const classes = useStyles();
  const { onSave, onCancel, player = null, existingPlayers } = props;
  const [name, setName] = useState<string>(player ? player.name : "");
  const [truthsCopy, setTruthsCopy] = useState<Statement[]>(
    player ? player.truths : []
  );
  const [validationText, setValidationText] = useState<string>("");
  const [highestExistingTruthId, setHighestExistingTruthId] = useState<number>(
    player
      ? Math.max.apply(
          Math,
          player.truths.map((t) => t.id)
        )
      : 0
  );
  const [newTruth, setNewTruth] = useState<string>("");
  const [showHelpText, setShowHelpText] = useState(false);

  const handleUpdatedPlayer = () => {
    setValidationText("");
    // Check no fields are empty
    if (name === "") {
      setValidationText("Please give this player a name");
      return;
    }
    truthsCopy.forEach((t) => {
      if (t.text === "") {
        setValidationText(
          "You can't have empty truths, please fill in truths or remove them."
        );
        return;
      }
    });
    // Check at least one truth
    if (truthsCopy.length === 0) {
      setValidationText("Please add at least one truth");
      return;
    }

    let updatedPlayer: Player | null = null;
    // If player is new, create the player
    if (player === null) {
      updatedPlayer = {
        id: existingPlayers.length,
        name: name,
        points: 0,
        truths: truthsCopy,
      } as Player;
    } else {
      // Else update the existing player
      updatedPlayer = {
        id: player.id,
        name: name,
        points: 0,
        truths: truthsCopy,
      } as Player;
    }
    onSave(updatedPlayer);
  };

  const handleCancel = () => {
    // Display "are you sure?" modal if anything has changed
    onCancel();
  };

  const handleTruthChange = (event: any, id?: number) => {
    const existingTruth: Statement | undefined = truthsCopy.find(
      (t) => t.id === id
    );
    
    if (id && existingTruth) {
      let _truthsEdited = truthsCopy;
      // Update the truth in array copy
      _truthsEdited.forEach((t, index) =>
        t.id === id ? (_truthsEdited[index].text = event.target.value) : null
      );
      // Update array
      setTruthsCopy(_truthsEdited);
    } else {
      setNewTruth(event.target.value);
    }
  };

  const addTruth = () => {
    setValidationText("");
    const _truthsEdited: Statement[] = [...truthsCopy];

    _truthsEdited.push({
      id: highestExistingTruthId + 1,
      text: newTruth,
      factOrFib: FactOrFib.Fact,
      used: false,
    });

    setHighestExistingTruthId(highestExistingTruthId + 1);
    setTruthsCopy(_truthsEdited);
    setNewTruth("");
  };

  const removeTruth = (id: number) => {
    const _truthsEdited: Statement[] = truthsCopy.filter((t) => t.id !== id);
    setTruthsCopy(_truthsEdited);
  };

  return (
    <div className="modal-container">
      <div className="settingsTitleContainer">
        <h1
          className="settings-heading"
          style={{ color: "#495877", textShadow: "none", marginRight: 8 }}
        >
          Edit Player
        </h1>
        {/* <IconButton size="small" onClick={(e: any) => setShowHelpText(!showHelpText)}>
          <HelpIcon color="primary" />
        </IconButton> */}
      </div>
      {showHelpText && <Typography style={{ padding: 16 }}>Only this player should know about the truths they add. Try to keep your truths general/vague to blend in with the lies, avoiding things like names of people locations.</Typography>}
      <TextField
        label="Player name"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ width: "100%" }}
      />
      <Typography variant="h5" style={{ marginTop: 8 }}>Add a Truth</Typography>
      <div className="helper-container">
        <p style={{ fontSize: 14, textAlign: "center", margin: 3 }}>
          e.g. "I have never been abroad", "I once beat a celebrity at darts"
        </p>
        <p style={{ fontSize: 12, textAlign: "center", margin: 6, fontWeight: 100 }}>
          Tip: Keep your truths vague to blend in with the lies. Instead of "I stole food from Tom Cruise", try something like "I stole food from a celebrity"
        </p>
      </div>
      <div className={classes.newTruth}>
        <TextField
          id="new-truth"
          label="Your truth here"
          variant="outlined"
          value={newTruth}
          onChange={handleTruthChange}
          inputProps={{
            //className: classes.textField,
            classes: {
              root: classes.root,
            },
          }}
          style={{ width: "100%" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={addTruth}
          disabled={newTruth === ""}
          style={{ fontSize: 25 }}
        >
          +
        </Button>
      </div>
      <p style={{ fontSize: 16, textAlign: "center", margin: 3 }}>
        Keep your truths private and don't show this screen to anyone else!
      </p>
      <Typography variant="h5">My Truths</Typography>

      <div
        className={classes.existingTruthsCol}
        style={{
          justifyContent: truthsCopy.length === 0 ? "center" : undefined,
        }}
      >
        {truthsCopy.map((truth, i) => {
          return (
            <div className={classes.extistingTruthsRow} key={truth.id} id={truth.id.toString()}>
              <TextField
                variant="outlined"
                value={truth.text}
                onChange={(e: any) => handleTruthChange(e, truth.id)}
                style={{ width: "85%" }}
                disabled
              />
              <IconButton
                size="small"
                onClick={(e: any) => removeTruth(truth.id)}
              >
                <HighlightOffIcon color="primary" />
              </IconButton>
            </div>
          );
        })}
        {truthsCopy.length === 0 && (
          <Typography
            style={{ textAlign: "center", fontStyle: "italic", color: "grey" }}
          >
            Looks like you don't have any truths yet, add one below.
          </Typography>
        )}
      </div>

      {validationText && (
        <Typography className={"validation-text"}>{validationText}</Typography>
      )}
      <div className="buttonRow" style={{ marginBottom: 16 }}>
        <Button
          variant="contained"
          onClick={() => handleUpdatedPlayer()}
          color="primary"
          style={{ marginRight: 8 }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          onClick={() => handleCancel()}
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
