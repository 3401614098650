
export const getService = () => {
  let service = null;
  if ('getDigitalGoodsService' in window) {
    //@ts-ignore
    service = window.getDigitalGoodsService('https://play.google.com/billing');
    if (!service) {
      // console.log('Google Play Billing is not available');
    }
  } else {
    // console.log('Goods service not in window');
  }
  return service;
}

export const ProductIdToNameMap: { [key: string]: string } = {
  "offline_mode_full_features": "Fact or Fib Offline Premium",
  "premium": "Fact or Fib Premium",
  "premium_temp": "Fact or Fib Premium (Temporary)"
}
