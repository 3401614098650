import firebase from "firebase";


export const getUserToken = async (firebaseUser: firebase.User | null) => {
    /**
     * Use this to retrieve a token to pass to the API.
     * 
     * This will either return an existing valid token or handle refreshing.
     */
    let token: string | undefined = undefined;
    if (!!firebaseUser) {
        token = await firebase.auth().currentUser?.getIdToken();
    }
    return token;
}