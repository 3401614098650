import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../models/user";

const initialState: User = {
    firebaseUser: null,
    apiUser: null,
    ownedProductIds: [] as string[],
    gamesWon: 0
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUserFields: (state, action) => {
      const { firebaseUser, ownedProductIds, gamesWon } = action.payload;
      state.firebaseUser = firebaseUser;
      state.ownedProductIds = ownedProductIds;
      state.gamesWon = gamesWon;
    },
    addProductToUser: (state, action) => {
      const { sku } = action.payload;
      state.ownedProductIds.push(sku);
    },
    resetProducts: (state, action) => {
      state.ownedProductIds = initialState.ownedProductIds;
    },
    setProducts: (state, action) => {
      const { ownedProductIds } = action.payload;
      state.ownedProductIds = ownedProductIds;
    },
    setFirebaseUser: (state, action) => {
      const { firebaseUser } = action.payload;
      state.firebaseUser = firebaseUser;
    },
    setApiUser: (state, action) => {
      const { apiUser } = action.payload;
      state.apiUser = apiUser;
    }
  }
});

export const {
  setUserFields,
  addProductToUser,
  resetProducts,
  setProducts,
  setFirebaseUser,
  setApiUser
} = userSlice.actions;
