import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from "react-router-dom";
import { AccountManagement } from "../../components/organisms/AccountManagement";
import { AdBanner } from "../../components/atoms/AdBanner";
import { StoreModal } from "../../components/organisms/StoreModal";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { ONLINE_MODE_ENABLED } from "../../services/featureToggles";
import TextField from "@material-ui/core/TextField";
import { createGame, GAME_ID_STRING_LENGTH, joinGame } from "../../services/ApiGameService";
import { setToast } from "../../store/state/ui";
import { APIErrorResponse, GameServiceResponse } from "../../models/responses";
import { updateGame } from "../../store/state/online-game";


export const OnlineJoinOrCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // fireBaseUser can be null
  const currentUser = useSelector((x: AppState) => x.userReducer.firebaseUser);
  const currentGameId = useSelector((x: AppState) => x.onlineGameReducer.id);

  const [open, setOpen] = useState(true);
  const [gameId, setGameId] = useState(currentGameId ? currentGameId : '');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnClickJoin = async () => {
    // This is just to make typescript happy, we already redirect away
    // if the firebase user is not populated.
    if (!currentUser) return;

    setLoading(true);

    if (gameId.length !== GAME_ID_STRING_LENGTH) {
      dispatch({
        type: setToast,
        payload: { severity: "error", message: "The game ID you provided is not the right length." }
      });
      setLoading(false);
    } else {
      // Try API call to join game
      await joinGame(
        gameId,
        currentUser,
        (data: GameServiceResponse) => {
          dispatch({ type: updateGame, payload: data });
          history.push("/online-player-setup");
          setLoading(false);
        },
        (error: APIErrorResponse) => {
          setLoading(false);
          dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        }
      );
    }
  }

  const handleOnClickCreate = async () => {
    if (!currentUser) return;

    await createGame(
      currentUser,
      (data: GameServiceResponse) => {
        dispatch({ type: updateGame, payload: data });
        history.push("/online-game-settings");
        setLoading(false);
      },
      (error: APIErrorResponse) => {
        dispatch({ type: setToast, payload: { message: error.detail, severity: "error" } });
        setLoading(false);
      }
    )
  }

  const getModalContent = () => {
    return (<>
      <DialogTitle id="alert-dialog-title" style={{textAlign: "center"}}>{"Just so you know.."}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-content">
          <b>Fact or Fib works best when you can see and/or hear other players.</b>
        </DialogContentText>
        <DialogContentText id="alert-dialog-content-cont">
          If you're not playing in person,
           consider setting up a group video call on something like Facebook, Zoom, or Google Chat.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Ok, cheers...
        </Button>
      </DialogActions>
    </>);
  }

  useEffect(() => {
    if (!ONLINE_MODE_ENABLED || !currentUser) {
      history.push('/');
    };
    // Adding loading to the useEffect thing causes
    // reload on state change
  }, [loading, currentUser, history]);

  return (
    <div className="App">
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
      <div className="container">
        <h1>
          Fact Or Fib
        </h1>
        <p>Host a game for your friends to join</p>
        <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 16 }}
            onClick={() => {
              setLoading(true);
              handleOnClickCreate();
            }}
            disabled={loading}
        >
            Create game
        </Button>

        <p>Or join an existing game</p>
        <TextField
          variant="filled"
          label="Game ID"
          value={gameId}
          onChange={(e) => {
            if (!e.target.value.match(/[ !"£$%^&*()@#]/)) {
              setGameId(e.target.value)
            }
          }}
          inputProps={{ maxLength: GAME_ID_STRING_LENGTH }}
          style={{ width: 250, backgroundColor: "rgba(255,255,255, 0.9)" }}
        />

        {!loading ? (
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 16 }}
            disabled={!gameId || gameId === ''}
            onClick={handleOnClickJoin}
          >
            Join
          </Button>
        ) : (
          <CircularProgress style={{ marginTop: 16 }} />
        )}
        
        <AdBanner currentPath="landing" />
        <AccountManagement />
        <StoreModal />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {getModalContent()}
      </Dialog>
    </div>
  );
}
