import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import { toggleStoreModalOpen } from '../../store/state/ui';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/store";

const useStyles = makeStyles({
  container: {
    display: "flex",
    position: "fixed",
    bottom: "0%",
    minHeight: 60,
    width: '100%',
    maxWidth: 500,
    background: 'linear-gradient(90deg, rgba(63,81,181,1) 0%, rgba(63,81,181,1) 72%, rgba(0,219,255,1) 100%);',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 8,
    cursor: 'pointer',
    filter: 'drop-shadow(0px 0px 2px #000)',
    "& h1": {
      fontSize: 20,
      margin: 0,
      marginLeft: 8
    }
  },
  innterList: {
    "& p": {
      color: '#fff',
      fontSize: 16,
      textShadow: '2px 2px #000',
      margin: 0,
      textAlign: 'initial',
      display: 'flex',
      alignItems: 'center'
    }
  }
});


export const AdBanner = (props: { currentPath: string, googleAd?: boolean, zIndexBump?: boolean }) => {
  const classes = useStyles();
  const { currentPath, googleAd=false, zIndexBump=false } = props;
  const [adPopulated, setAdPopulated] = useState(false);
  const dispatch = useDispatch();
  const ownedProducts = useSelector((x: AppState) => x.userReducer.apiUser?.expansions_owned);
  const adFree = ownedProducts?.includes('premium') || ownedProducts?.includes('premium_temp');

  // useEffect(() => {
  //   if (!adFree && googleAd && !adPopulated) {
  //     // @ts-ignore
  //     window.adsbygoogle = window.adsbygoogle || [];
  //     // @ts-ignore
  //     window.adsbygoogle.push({});
  //     setAdPopulated(true);
  //   }
  // }, [setAdPopulated, adPopulated, googleAd, adFree]);

  // TODO: Implement store at native TWA level and re-open browser store
  return <></>
  // if (adFree) return <></>;

  // if (googleAd) {
  //   return (
  //     <div key={currentPath}>
  //       <ins
  //         className="adsbygoogle"
  //         style={{
  //           display: "inline-block",
  //           width: 500,
  //           height: 90,
  //           float: "left",
  //         }}
  //         // style="display:inline-block;width:728px;height:90px"
  //         data-ad-client="ca-pub-7640562161899788"
  //         data-ad-slot="7259870550"
  //       />
  //     </div>
  //   );
  // }

  // return (
  //   <div className={classes.container} style={{ zIndex: zIndexBump ? 9999 : 0 }} onClick={() => dispatch({ type: toggleStoreModalOpen })}>
  //     <h1>Get premium</h1>
  //     <div className={classes.innterList}>
  //       <p><CheckCircleIcon color="secondary" /> Unlock all settings</p>
  //       <p><CheckCircleIcon color="secondary" /> Ad free experience</p>
  //     </div>
  //   </div>
  // )
};
