import firebase from "firebase/app";
import { OnlineGame, OnlineGameSettings } from "../models/game";
import { APIErrorResponse, GameServiceResponse, isGameServiceResponse } from "../models/responses";
import { API_BASE_URL, getApiRequestHeaders, returnError } from "./ApiCommon";

export const API_GAMES_ENDPOINT = 'games/'
export const GAME_ID_STRING_LENGTH = 6


export const createGame = async (
    user: firebase.User,
    onSuccess: (data: GameServiceResponse) => void,
    onError: (error: APIErrorResponse) => void
) => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'POST',
        headers: headers
    };

    fetch(API_BASE_URL + API_GAMES_ENDPOINT + 'create', request)
        .then(response => response.json())
        .then(data => {
            if (isGameServiceResponse(data)) {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        })
}

export const updateTruths = async (
    truths: string[],
    game: OnlineGame,
    user: firebase.User,
    onSuccess: (data: GameServiceResponse) => void,
    onError: (data: APIErrorResponse) => void
) => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(truths)
    };

    fetch(API_BASE_URL + API_GAMES_ENDPOINT + game.id + '/update_truths', request)
        .then(response => response.json())
        .then(data => {
            if (isGameServiceResponse(data)) {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        })
}

export const toggleReady = async (
    game: OnlineGame,
    user: firebase.User,
    onSuccess: (data: GameServiceResponse) => void,
    onError: (data: APIErrorResponse) => void
) => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'PUT',
        headers: headers,
    };

    fetch(API_BASE_URL + API_GAMES_ENDPOINT + game.id + '/toggle-ready', request)
        .then(response => response.json())
        .then(data => {
            if (isGameServiceResponse(data)) {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        })
}

export const setGuess = async (
    isFactual: boolean,
    game: OnlineGame,
    user: firebase.User,
    onSuccess: (data: GameServiceResponse) => void,
    onError: (data: APIErrorResponse) => void
) => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'PUT',
        headers: headers,
    };

    const isFactualString = isFactual ? 'true' : 'false';

    fetch(API_BASE_URL + API_GAMES_ENDPOINT + game.id + '/set-guess?is_factual=' + isFactualString, request)
        .then(response => response.json())
        .then(data => {
            if (isGameServiceResponse(data)) {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        })
}

export const updateGame = async (
    gameSettings: OnlineGameSettings,
    gameId: string,
    user: firebase.User,
    onSuccess: (data: GameServiceResponse) => void,
    onError: (data: APIErrorResponse) => void
) => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(gameSettings)
    };

    fetch(API_BASE_URL + API_GAMES_ENDPOINT + gameId + '/settings', request)
        .then(response => response.json())
        .then(data => {
            if (isGameServiceResponse(data)) {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        })
}

export const joinGame = async (
    gameId: string,
    user: firebase.User,
    onSuccess: (data: GameServiceResponse) => void,
    onError: (data: APIErrorResponse) => void
) => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'PUT',
        headers: headers,
    };

    fetch(API_BASE_URL + API_GAMES_ENDPOINT + gameId + '/join', request)
        .then(response => response.json())
        .then(data => {
            if (isGameServiceResponse(data)) {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        })
}

export const leaveGame = async (
    game: OnlineGame,
    user: firebase.User,
    onSuccess: () => void,
    onError: (data: APIErrorResponse) => void
) => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'PUT',
        headers: headers,
    };

    fetch(API_BASE_URL + API_GAMES_ENDPOINT + game.id + '/leave', request)
        .then(response => {
            if (response.status === 200) {
                onSuccess();
            }
            response.status === 200 ? onSuccess() : onError({ detail: "Unexpected error" });
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        })
}

// I think this is currently what triggers a move on in the game state
// without a webhook
export const checkRoundOver = async (
    game: OnlineGame,
    user: firebase.User,
    onSuccess: (roundOver: boolean) => void,
    onError: (data: APIErrorResponse) => void
): Promise<void> => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'PUT',
        headers: headers,
    };

    fetch(API_BASE_URL + API_GAMES_ENDPOINT + game.id + '/check-round-over', request)
        .then(response => response.json())
        .then(data => {
            if (typeof data === "boolean") {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        })
}

export const getGame = async (
    gameId: string,
    user: firebase.User,
    onSuccess: (data: GameServiceResponse) => void,
    onError: (data: APIErrorResponse) => void
) => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'GET',
        headers: headers,
    };

    fetch(API_BASE_URL + API_GAMES_ENDPOINT + gameId, request)
        .then(response => response.json())
        .then(data => {
            if (isGameServiceResponse(data)) {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        })
}

export const startGame = async (
    gameId: string,
    user: firebase.User,
    onSuccess: (data: GameServiceResponse) => void,
    onError: (data: APIErrorResponse) => void
) => {
    const headers = await getApiRequestHeaders(user);

    const request = {
        method: 'PUT',
        headers: headers,
    };

    fetch(API_BASE_URL + API_GAMES_ENDPOINT + gameId + '/start', request)
        .then(response => response.json())
        .then(data => {
            if (isGameServiceResponse(data)) {
                onSuccess(data);
            } else {
                const return_error = returnError(data);
                onError(return_error);
            }
        })
        .catch(error => {
            const return_error = returnError(error);
            onError(return_error);
        })
}
