import firebase from "firebase/app";
import { getUserToken } from "../helpers/UserHelpers";
import { APIErrorResponse, isError } from "../models/responses";

// TODO: Get this from env var/file
// export const API_BASE_URL = "http://localhost:8000/";//process.env.REACT_APP_API_BASE_URL;
export const API_BASE_URL = "https://api.factorfib.jagetech.com/";
// export const API_BASE_URL = "http://localhost:8000/";
export const SERVER_COMMS_ERROR = "Can't communicate with Fact or Fib server";

// @ts-ignore
export const IS_LOCAL = API_BASE_URL === "http://localhost:8000/";

/**
 * Polling settings
 * 
 * Ideally we want to lean on websockets and use polling as a fallback.
 */
export const API_POLLING_ENABLED = false;
export const API_UPDATE_POLL_TIME = IS_LOCAL ? 8000 : 4000;

export const getApiRequestHeaders = async (user: firebase.User) => {
    /**
     * Prepare headers with authorization for the current user to 
     * do stuff with the backend API.
     */
    const token = await getUserToken(user);
    return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Accept': '*/*',
        'Accept-Encoding': 'gzip, deflate, br',
        'Connection': 'keep-alive',
    };
}

export const returnError = (error: any): APIErrorResponse => {
    if (!isError(error)) {
        return {
            detail: SERVER_COMMS_ERROR
        };
    } else {
        return error;
    }
}
