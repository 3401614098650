// https://redux.js.org/introduction/getting-started
// https://redux.js.org/recipes/usage-with-typescript

import { configureStore } from '@reduxjs/toolkit';
import { gameSlice } from './state/game';
import { uiSlice } from './state/ui';
import { userSlice } from './state/user';
import { combineReducers } from 'redux';
import { useDispatch } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist'
import { Game, OnlineGame } from '../models/game';
import { UiState } from '../models/ui';
import { User } from '../models/user';
import { onlineGameSlice } from './state/online-game';
import { IS_LOCAL } from '../services/ApiCommon';

const persistConfig = {
  key: 'root',
  storage
};

export interface AppState {
  gameReducer: Game,
  onlineGameReducer: OnlineGame,
  userReducer: User,
  uiReducer: UiState
}

const reducers = combineReducers({
  gameReducer: gameSlice.reducer,
  onlineGameReducer: onlineGameSlice.reducer,
  uiReducer: uiSlice.reducer,
  userReducer: userSlice.reducer
})
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: IS_LOCAL,
});


// Can still subscribe to the store
//store.subscribe(() => console.log(store.getState()));

export type GameDispatch = typeof store.dispatch;
export const useGameDispatch = () => useDispatch<GameDispatch>();

// // Still pass action objects to `dispatch`, but they're created for us
// store.dispatch(incremented())
// // {value: 1}
// store.dispatch(incremented())
// // {value: 2}
// store.dispatch(decremented())
// // {value: 1}